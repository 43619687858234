<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
    :Home="Home"
    :order="order"
    :movement="movement"
    :City="City"
    :account="account"
    :Delegates="Delegates"
  />
  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">

        <div class="padding-t" >
          <h3 class="add_h3">قائمة الطرود</h3>
        </div>

        <div
          v-if="loading != true"
          class="input_label"
          style="padding-top: 1rem"
        >
          <div class="search">
            <a class="btn btn-danger" @click="search()">
              <i class="fas fa-search"></i>
            </a>
            <select
              v-model="st"
              style="
                direction: rtl;
                padding-right: 40px;
                font-size: 15px;
                font-family: var(--cairo);
              "
              class="form-select"
              aria-label="Default select example"
            >
              <option
                :value="statuse.id"
                v-for="statuse in statuses"
                :key="statuse.id"
              >
                {{ statuse.name }}
              </option>
            </select>
          </div>
          <label class="col-form-label labl_order">البحث بالحالة</label>
        </div>


                <div
          v-if="loading != true"
          class="input_label"
        >
          <div class="search">
            <a class="btn btn-danger" @click="searchBarcode()">
              <i class="fas fa-search"></i>
            </a>
          <input
            v-model="barcode"
            class="form-control input_order"
            type="text"
          />
          </div>
          <label class="col-form-label labl_order">البحث بالبركود </label>
        </div>


        <comloading v-if="loading == true" />

        <table
          v-if="display == true && display != null"
          :class="{ table_dis: !display }"
          class="table"
        >
          <thead class="top_table">
            <tr>
              <th style="width: 105px" class="col" scope="col">كود الطرد</th>
              <th class="col" scope="col">حالة الطرد</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders" :key="order.id">
              <td>{{ order.barCode }}</td>
              <td>
                {{ order.status.name }}


              <div class="adit_delete">
                <a  @click="show(order.id)" class="btn btn-danger">
                    <i class="far fa-list-alt"></i>
                </a>
              </div>
                
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="display != true && display != null"
          :class="{ img_dis: display }"
        >
          <img src="img/noData.jpg" alt="" style="max-width: 80%" />
          <p class="nodata">...لا يوجد لديك طرود مضافة</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import appheader from "../../components/header.vue";

import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },

  props: ["URL"],
  data: function () {
    return {
      loading: false,
      width_vue: "0",
      left_vue: "-27rem",
      Home: false,
      order: false,
      movement: false,
      City: false,
      account: false,
      Delegates: true,

      code: "",
      orders: [],
      display: null,
      statuses: [],
      st:"",
      barcode:"",
      key: "",
    };
  },

  async created() {

    let token = localStorage.getItem("qdtoken");
    this.loading = true;
    this.code = window.atob(localStorage.getItem("localStoragecode"));
    await axios.get(this.URL + "api/statuses/GetSupplierStatuses",{ headers: { Authorization: "Bearer " + token }}).then((res) => {
      this.statuses = res.data.data;
      if ( localStorage.getItem('idstate') == null ) 
      {
      this.st = this.statuses[0].id;
      }
      else {
          this.st = window.atob(localStorage.getItem('idstate')) ;
      }
    });


      await axios
        .get(this.URL + "api/Orders/GetBySuuplierStatus/" + this.st + "/" + this.code,{ headers: { Authorization: "Bearer " + token }})
        .then((res) => {
          this.orders = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      if (this.orders.length == 0) {
        this.display = false;
      } else {
        this.display = true;
      }
    },


  mounted() {
    if (localStorage.getItem("localStoragecode") != null) {
      this.$router.push({ path: "/Delegates" });
    } else {
      this.$router.push({ path: "/login" });
    }
  },

  methods: {

  show(id){
      localStorage.setItem("id", window.btoa(id));
      localStorage.setItem("idstate", window.btoa(this.st));
      this.$router.push('/Delegates-Detalis')
  },
    
    async search() {
    let token = localStorage.getItem("qdtoken");
        this.code = window.atob(localStorage.getItem("localStoragecode"));

      this.loading = true;
      await axios
        .get(this.URL + "api/Orders/GetBySuuplierStatus/" + this.st + "/" + this.code,{ headers: { Authorization: "Bearer " + token }})
        .then((res) => {
          this.orders = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      if (this.orders.length == 0) {
        this.display = false;
      } else {
        this.display = true;
      }
    },

        async searchBarcode() {
    let token = localStorage.getItem("qdtoken");
        this.code = window.atob(localStorage.getItem("localStoragecode"));

      this.loading = true;
      await axios
        .get(this.URL + "api/Orders/GetBySupplierBarCode/" + this.barcode + "/" + this.code,{ headers: { Authorization: "Bearer " + token }})
        .then((res) => {
          this.orders = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      if (this.orders.length == 0) {
        this.display = false;
      } else {
        this.display = true;
      }
    },


    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 20px 50px;
}


.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
}
.table {
  direction: rtl;
}

tr {
  text-align: start;
  font-family: var(--cairo);
}

.top_table {
  background-color: var(--one);
  color: var(--tow);
}

th,
td {
  padding: 15px 31px 15px 0px;
}
td {
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 1rem 1rem 2rem 2rem;
  font-size: 20px;
}

.btnAdd {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: none;
  position: absolute;
  top: 53px;
  left: 1px;
  padding: 7px 10px 7px 10px;
}
.btnAdd:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

.adit_delete {
  position: absolute;
  left: 4px;
  top: 8px;
}

.btn-danger {
  font-size: 17px;
  background-color: var(--one) ;
  border: 2px solid var(--one) ;
  margin-right: 10px;
}
.btn-danger:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

.search {
  display: contents;
}

.padding-t {

 padding-top: 5rem;
 position: relative

 }



@media (max-width: 872px) {
  .container {
    padding: 6rem 1rem 9rem 1rem;
    min-height: 0vh;
  }

 
}

@media (max-width: 339px) {
  .container {
    padding: 9rem 1rem 9rem 1rem;
  }

  td,
  th {
    padding: 15px 10px 15px 0px;
  }
}

@media (max-width: 800px) {

  .padding-t {

 padding-top: 1rem;
 position: relative
 
 }


  .bord {
    background-color: #fff;
    border: none !important;
    border-radius: 0px;
    padding: 0px;
  }
  .backgd {
    background-color: var(--tow) !important;
  }
}
.nodata {
  text-align: center;
  font-family: var(--cairo);
  color: var(--one);
  font-size: 19px;
  padding: 0px 5px 0px 10px;
}

.table_dis {
  display: none;
}

.img_dis {
  display: none;
}

.input_label {
  display: flex;
  padding-bottom: 20px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 268px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 872px) {
  
   .search {
    display: flex;
    flex-direction: row-reverse;
  }
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 5px 30px;
  }
}
</style>
