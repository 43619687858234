<template>
  <div class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">
        <h3 class="add_h3">لتأكيد الحساب الرجاء مراجعة الإيميل الخاص بك</h3>
        <div style="text-align: end">
          <a
            style="
              font-family: var(--cairo);
              color: var(--one);
              padding: 0rem 1rem 2rem 2rem;
              font-size: 18px;
            "
            href="https://mail.google.com/"
            >اضغظ هنا</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 50px 50px;
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 30px;
  }
}

</style>
