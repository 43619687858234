<template>
  <header id="header" class="header">
    <div id="app" class="navigation">
      <div class="container" style="max-width: 123rem;">
        <div class="menu" :style="{ width: width_vue, left: left_vue }">
          <div class="menu__top">
            <a v-show=" !bransh"  @click="close()" class="close__toggle ">
              <i class="close__to fas fa-times" ></i>
            </a>

             <a v-show=" bransh" href="#" @click="close_bransh()" class="back" style="position: absolute;
    left: 25px;
    z-index: 100;
    top: 33px;
}">
              <i class="fas fa-arrow-left" style="color:var(--one)"></i>
            </a>
          </div>
        
          <ul v-show=" !bransh" class=" nav__list" style="
    text-align: end;
    line-height: 60px;
    border-radius: 50px 50px 0px 0px;">
              <div   class="name_code">
              <p v-if=" name != null" class="name" >{{name}}</p>
              <p v-if=" name != null" class="code" >{{code}}</p>
              </div>
              <router-link to="/">
                            <li @click="close()" class="li_s" style="margin-top: 10px;"> <span><i  class="fas fa-home"></i></span>الرئيسية </li>
              </router-link>
              <router-link   v-if="role != 2  && name != null" to="/OrderIndex">
              <li  @click="close()" class="li_s"> <span><i  class="fas fa-plus"></i></span>اضافة طرد</li>
              </router-link>
              <router-link v-if="role != 2 && name != null" to="/movement">
              <li  @click="close()" class="li_s"> <span><i  class="fas fa-box"></i></span>تتبع الطرود</li>
              </router-link>

              <router-link v-if="role == 2  && name != null" to="/Delegates">
              <li  @click="close()" class="li_s"> <span><i  class="fas fa-box"></i></span>طرود المندوبين</li>
              </router-link>


              <router-link to="/BrunshCity">
              <li @click="close()"  class="li_s"> <span><i  class="fas fa-money-bill-wave"></i></span>اسعار التوصيل</li>
              </router-link>
              <router-link v-if="role != 2 && name != null" to="/account">
              <li @click="close()"  class="li_s"> <span><i class="far fa-address-card"></i></span>حسابي</li>
              </router-link>
              <li  v-if="role == 159357"  @click="open_bransh()"  class="li_s"> <span><i class="fas fa-briefcase"></i></span>ادارة الفروع</li>
              <!-- <li  class="li_s"> <span><i  class="fas fa-sign-out-alt"></i></span>تسجيل الخروج</li> -->

           
         
          </ul>



  <ul v-show="bransh" class=" nav__list" style="
    text-align: end;
    line-height: 60px;
    border-radius: 50px 50px 0px 0px;">
            <div  class="name_code">
              <p v-if=" name != null" class="name" >{{name}}</p>
              <p v-if=" name != null" class="code" >{{code}}</p>
              </div>
              <router-link to="/provinceIndex">
              <li @click="close()" style="margin-top: 10px;"  class="li_s"><span><i class="fas fa-campground"></i></span> الأقاليم </li>
              </router-link>
              <router-link to="/brunchAdminIndex">
              <li @click="close()" class="li_s"><span><i class="fas fa-project-diagram"></i></span> الفروع</li>
              </router-link>
              <router-link to="/movementIndix">
              <li  @click="close()" class="li_s"><span><i class="fas fa-recycle"></i></span> الحركات</li>
              </router-link>

              <router-link to="/accountAdminIndex">
              <li @click="close()"  class="li_s"> <span><i class="fas fa-user-alt"></i></span> المستخدمين</li>
              </router-link>
            

           
         
          </ul>


        </div>
        <nav class="nav">
          <div class="nav__logo">
            <a    class="scroll-link">
            <img class="logo_left" :src="logo" alt=""  style="width: 125px; margin-left: 15px; height: 100%;">

         
            </a>
          </div>

          <div @click="open()" class="nav__hamburger">
            <i class=" fas fa-bars" style="color:var(--white)"></i>
          </div>
          <div class="nav__icons ">
            <img class="logo_right" :src="logo" alt=""  style=" height:58px;">
             
                <div class="top_nav_all">

                  <div   class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="margin-left: 10px;
    color: var(--tow);
    background-color: var(--y);
    /* border-radius: 0.5rem; */
    cursor: pointer;
    padding: 2px 10px 0px 10px;
    /* transition: 0.5s; */
    border: none;
    font-size: 18px;box-shadow: none;">
          </button>
          <ul v-if="name != null" class="dropdown-menu name" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" href="#">{{name}}</a></li>
            <li><a class="dropdown-item" href="#">{{code}}</a></li>
          </ul>
        </div>
                <router-link @click="active_home()" :class="{'top_nav_activ':Home}"  class="top_nav"   to="/"  >

                الرئيسية
              </router-link>
              <router-link  v-if="role != 2  && name != null" @click="active_Order()" :class="{'top_nav_activ':order}" class="top_nav "  to="/OrderIndex">
              اضافة طرد
              </router-link>
              <router-link v-if="role != 2 &&  name != null"  @click="active_movement()" :class="{'top_nav_activ':movement}" class="top_nav" to="/movement">
                تتبع الطرود
              </router-link>

              <router-link v-if="role == 2  && name != null" @click="active_Delegates()" :class="{'top_nav_activ':Delegates}" class="top_nav" to="/Delegates">
                 طرود المندوبين
              </router-link>
              
              <router-link  @click="active_City()" :class="{'top_nav_activ':City}" class="top_nav" to="/BrunshCity">
              اسعار التوصيل
              </router-link>
              <router-link v-if="role != 2  && name != null"  @click="active_account()" :class="{'top_nav_activ':account}" class="top_nav" to="/account">
              حسابي
              </router-link>

                    <div  v-if="role == 159357"  class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="color: var(--tow);
    background-color: var(--one);
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 5px 10px 7px 10px;
    transition: 0.5s;
    border: none;
    font-size: 1.3rem;
    box-shadow: none;">
          ادارة الفروع
        </button>
        <ul class="dropdown-menu Admin" aria-labelledby="dropdownMenuButton1">
          <li><router-link to="/provinceIndex" class="dropdown-item dropdown-Admin" href="#">الأقاليم</router-link></li>
          <li><router-link to="/brunchAdminIndex" class="dropdown-item dropdown-Admin" href="#">الفروع</router-link></li>
          <li><router-link to="/movementIndix" class="dropdown-item dropdown-Admin" href="#">الحركات</router-link></li>
          <li><router-link to="/accountAdminIndex" class="dropdown-item dropdown-Admin" href="#">المستخدمين</router-link></li>

        </ul>
      </div>
         </div>

          </div>
        </nav>
      </div>
       
           

    </div>

  


  </header>
  
</template>

<script>
import foot1 from '../assets/qdL200.png';
export default {

  

  props:['width_vue','left_vue','Home','order','movement','City','account','Delegates'],
  data: function() {
    return {
      logo : foot1,
      bransh:'',
      // Home:true,
      // order:false,
      // movement:false,
      // City:false,
      // account:false,
      name:'',
      code:'',
      role:'',
      nameL:localStorage.getItem('localStorageName'),
    };
  },
mounted(){
    this.name=localStorage.getItem('localStorageName');
    this.code=window.atob(localStorage.getItem('localStoragecode'));
    this.role=window.atob(localStorage.getItem("localStoragerole"));
},


  methods: {

     
    open() {
      this.$emit('open');
    },

    close() {
      this.$emit('close');
    },
    close_bransh(){
      this.bransh =false;
    },
      open_bransh(){
      this.bransh =true;

    },

    // active_home(){
    //  this.Home=true;
    //  this.order=false;
    //  this.movement=false;
    //  this.City=false;
    //  this.account=false;
    // },

    // active_Order() {
    //  this.Home=false;
    //   this.order=true;
    //   this.movement=false;
    //   this.City=false;
    //   this.account=false;
    // },

    //  active_movement() {
    //  this.Home=false;
    //   this.order=false;
    //   this.movement=true;
    //   this.City=false;
    //   this.account=false;
    // },


    //  active_City() {
    //  this.Home=false;
    //   this.order=false;
    //   this.movement=false;
    //   this.City=true;
    //   this.account=false;
    // },

    //  active_account() {
    //  this.Home=false;
    //   this.order=false;
    //   this.movement=false;
    //   this.City=false;
    //   this.account=true;
    // }

      
  },

  
  name: "headerApp",
};
</script>

<style scoped>

/*  ======================
        Header
    ======================
*/

.header {
  position: relative;
}

.container {
  max-width: 81rem;
  margin: 0 auto;
  padding: 0 1.6rem;
}

/*
======================
   Navigation
======================
 */

.navigation {
 
  height: 7rem;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  background-color:var(--one);
  position: fixed;
    z-index: 400;
    width: -webkit-fill-available;
    border-bottom: 3px solid var(--y);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  height: 7rem;
  padding: 0 1rem;
}



.fix__nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color:var(--tow);
  z-index: 1200;
}

.menu {
 background-color: var(--one);
  height: 103%;
  transition: all 0.4s ease-in-out;
  position: fixed;
  z-index: 2;
border-right: 5px solid var(--y);
margin-top: -14px;
}

.menu_open {
  width: 27rem;
  left: 0;
}

.nav__hamburger {
  position: absolute;
  color: var(--tow);
    font-size: 22px;
    display: none;
}

.nav__link {
  color: var(--tow);
  font-weight: bold;
}

.menu .nav__item {
  text-align: right;
  line-height: 50px;
  /*  border-bottom: 1px solid var(--black);*/
  padding-right: 21px;
}

.menu__top {
  text-align: right;
  margin: 20px 20px 0 0;
  font-size: 23px;
}

.nav__logo a {
  font-size: 2.5rem;
  color: var(--tow);
  padding-left: 3.6rem;
  font-weight: 700;
}

.nav__menu {
  width: 50%;
}

.nav__icons {
  display: flex;
  position: relative;
}
.login{
  padding: 9px 15px 9px 15px;
    font-size: 13px;
    background-color: var(--one);
    border: var(--one);
    margin-left: 18px;
    font-family: var(--cairo);
}


.create_account{
  padding: 5px 15px 5px 15px;
    font-size: 13px;
    background-color: var(--tow);
    border: 2px solid var(--one);
    margin-left: 18px;
    color: var(--one);
    font-family: var(--cairo);
    font-weight: bold;
}
.create_account:hover{
 padding: 5px 15px 5px 15px;
    font-size: 13px;
    background-color: var(--one);
    border: 2px solid var(--tow);
    margin-left: 18px;
    color: var(--tow);
    font-family: var(--cairo);
    font-weight: bold;
    border-radius: 3px;
   
}

.logo_right{
  display: none;
}

.li_s{
  color: var(--tow);
    padding-right: 25px;
    font-family: var(--cairo);
}

.li_s i{
  color: var(--y);
 
}

.li_s:hover{
    color: var(--one);
    background-color: var(--tow);
    font-weight: bold;
    
}

.name_code{
    background-color: var(--tow);
    padding-right: 25px;
    font-family: var(--cairo);
    font-weight: bold;
    position: relative;
    height: 60px;
    border-right: 5px solid var(--one);
    border-left: 5px solid var(--one);
}
.name , .code  {
   color: var(--one);
}


.code {
  position: absolute;
    right: 45px;
    top: 11px;
}

.name {
position: absolute;
    right: 45px;
    top: -11px;
}

ul{
  padding-left: 0 !important;
}

.li_s span {
 position: absolute;
    left: 26px;
    font-size: 20px;
}

.close__to {
  position: absolute;
    z-index: 100;
    color: var(--one);
    top: 39px;
    right: 17px;
}

@media(max-width:800px){
  
  .login {
    display: none;
  }

  .create_account {
    display: none;
  }

  .logo_right{
  display: block;
}

.logo_left{
  display: none;
}

}

.top_nav {
  color: var(--tow) ;
    margin-left: 17px;
}
.top_nav_all {

display: flex;
    flex-direction: row-reverse;
        font-family: var(--cairo) ;
}

@media (max-width:800px) {
  .top_nav_all {
    display: none;
  }

  .nav__hamburger {
        display: block;
  }
}
.top_nav_activ {
    color: var(--one);
    background-color: var(--y);
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 2px 10px 3px 10px;
        transition: 0.5s;

}

.dropdown-toggle::after {
   vertical-align: 0.200em !important;
}

  .name.show{
     transform: translate(-111px, 37px) !important;
    width: 155px;
    font-size: 15px;
    background-color:var(--one);
    position: absolute;
    line-height: 30px;
  }

  .Admin.show {
         transform: translate(-60px, 37px) !important;
    width: 155px;
    font-size: 15px;
    background-color:var(--one);
    position: absolute;
    line-height: 30px;

  }

  .dropdown-Admin:hover  {
        color: var(--tow) !important ;
    background: var(--y) !important;

  }

  .dropdown-item {
    color: var(--tow) !important;
    display: flex !important;
    direction: rtl;
  }

  .dropdown-item:hover {
    color: var(--tow) ;
    background: var(--one);
  }



</style>
