<template>
  <footer class="footer">
    <div class="footer_color">
      <h3>فروعنا</h3>
      <div class="box-container">
        <div class="box">
          <a href="tel:094 515 5626"
            ><p class="card-text">
              <i class="fas fa-phone"></i>الفرع الرئيسي-زناتة
            </p></a
          >
         <a href="tel:092 353 4525"
            ><p class="card-text">
              <i class="fas fa-phone"></i>مكتب قرجي
            </p></a
          >

           <a href="tel:094 417 0203"
            ><p class="card-text">
              <i class="fas fa-phone"></i> مكتب بنغازي
            </p></a
          >
         
        </div>


                <div class="box">
         
        

                    <a href="tel:092 199 8491"
            ><p class="card-text"><i class="fas fa-phone"></i> مكتب طبرق</p></a
          >
          <a href="tel:094 472 2580">
            <p class="card-text"><i class="fas fa-phone"></i> مكتب درنة</p></a
          >

          <a href="tel:092 052 7151"
            ><p class="card-text">
              <i class="fas fa-phone"></i> مكتب البيضاء
            </p></a
          >
        </div>


        <div class="box">
          <a href="tel:092 264 6160"
            ><p class="card-text">
              <i class="fas fa-phone"></i> مكتب مصراتة
            </p></a
          >
          <a href="tel:094 510 8887">
            <p class="card-text"><i class="fas fa-phone"></i> مكتب سبها</p></a
          >
          <a href="tel:092 750 5536"
            ><p class="card-text">
              <i class="fas fa-phone"></i> مكتب الزاوية
            </p></a
          >
        </div>

        <div class="box">
          <a href="tel:092 313 3859"
            ><p class="card-text"><i class="fas fa-phone"></i> مكتب الخمس</p></a
          >
          <a href="tel:092 766 1011"
            ><p class="card-text">
              <i class="fas fa-phone"></i> مكتب ترهونة
            </p></a
          >
          <a href="tel:092 910 5910"
            ><p class="card-text"><i class="fas fa-phone"></i> مكتب زليتن</p></a
          >
        </div>

        <!-- <div class="box">
        </div> -->
        <!-- <div class="box">
                 <h3>صفحتنا على الفيس</h3>
               <a class="a_face" href="https://www.facebook.com/%D9%83%D9%88%D9%8A%D9%83-%D8%AF%D9%8A%D9%84%D9%81%D9%8A%D8%B1%D9%8AQuick-delivery-160957531211914">كويك ديلفيري <i style="color: var(--one);" class="fab fa-facebook"></i></a>
                </div>  

                <div class="box">
                 <h3> <i class="fas fa-chevron-down"></i>البريـد الإلكتروني</h3>
                 <a href="">qdlibya@gmail.com <i style="color: var(--one);" class="far fa-envelope"></i></a>
                </div>                    -->
      </div>
    </div>
    <div class="credit">
      جميع الحقوق محفوظة لدى شركة كويك ديلفيري -تنفيذ شركة المطور للبرمجيات ©
    </div>
  </footer >
</template>

<style scoped>
.footer_color {
  background-color: var(--one);
  font-size: 13px;
  font-family: var(--cairo);
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: end;
  padding-right: 30px;
  color: black;
}
.footer .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1.5rem;
  direction: rtl;
  padding-left: 50px;
}

.footer h3 {
  font-size: 20px;
  color: var(--tow);
}

.card-text {
  font-size: 13px;
  color: var(--tow);
  background-color: var(--one);
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.6rem 3rem;
  font-family: var(--cairo);
  font-weight: 500;
}

.card-text i {
  padding-left: 7px;
}
.credit {
  text-align: center;
  border-top: 0.1rem sold var(--tow);
  font-size: 16px;
  color: white;
  padding: 0.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-family: var(--cairo);
  background-color: var(--one);
    border-top: 2px solid;

}

@media (max-width: 874px) {
  .credit {
    font-size: 14px;
  }
}

@media (max-width: 444px) {
  .credit {
    font-size: 8px;
  }

  .footer .box-container {
    gap: 0;
    text-align: start;
    margin-top: 18px;
    padding-left: 0px;
  }
}

@media (max-width: 495px) {
  .footer .box-container {
    padding-left: 0px;
  }
}
</style>
