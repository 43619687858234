<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />

  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 390px">
      <div
        v-if="message != ''"
        class="alert alert-primary d-flex align-items-center"
        role="alert"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
          viewBox="0 0 16 16"
          role="img"
          aria-label="Warning:"
        >
          <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          />
        </svg>
        <div style="padding-right: 10px">
          {{ message }}
        </div>
      </div>
      <div class="bord">
        <img
          :src="logo"
          alt=""
          style="width: 130px; margin: 20px 9px 35px 20px ;border-radius: 11px;
    padding: 10px;
    background-color: var(--one);"
        />

        <input
          v-model="v$.login.email.$model"
          class="form-control user"
          type="text"
          placeholder="البريد الإلكتروني"
        />
        <input
          style="margin-bottom: 17px"
          v-model="login.password"
          class="form-control user"
          type="password"
          placeholder="كلمة المرور"
        />

        <label class="col-12 labelOk form-check-label">
          <input
            v-model="delegate"
            class="OK form-check-input"
            id="Ok"
            type="checkbox"
          />
          مندوب توصيل
        </label>

        <input
          v-show="!confirm_acc"
          @click="login_()"
          class="btn"
          type="submit"
          name=""
          value="تسجيل الدخول"
        />

        <input
          v-show="confirm_acc"
          @click="Confirm()"
          class="btn"
          type="submit"
          name=""
          value="تأكيد الحساب"
        />
        <div class="options-1" style="text-align: end; margin-bottom: 20px">
          <a @click="Email">هل نسيت كلمة السر ؟</a>
        </div>

        <comloading
          style="width: 100px; margin: -5px auto 0px !important"
          v-if="loading == true"
        />

        <div class="how">
          <p class="one">هل تريد إنشاء حساب؟</p>
          <router-link to="/createAccount">
            <p class="tow">إنشاء حساب</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import foot1 from '../assets/qdL200.png';

import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import appheader from "../components/header.vue";

import comloading from "../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },
  props: ["URL","URLFrontend"],
  data: function () {
    return {
      logo : foot1,
      confirm_acc: false,
      loading: false,
      v$: useVuelidate(),
      width_vue: "0",
      left_vue: "-27rem",
      delegate: false,
      version: localStorage.getItem("version"),

      login: {
        email: "",
        password: "",
      },
      user: {
        code: "",
        name: "",
      },
      message: "",
    };
  },

  validations() {
    return {
      login: {
        email: { required },
        password: { required },
      },
    };
  },
  methods: {
    async Confirm() {
      this.loading = true;
      axios
        .get(
          this.URL +
            "api/webusers/SendEmailConfirm/" +
            this.login.email +
            "?uri=" +
            this.URLFrontend +
            "EmailConfirm"
        )
        .then((res) => {
          res;
          this.loading = false;
          this.$router.push({ path: "/togmail" });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    Email() {
      this.$router.push({ path: "/Email" });
    },
    async login_() {
      if (this.delegate === false) {
        this.loading = true;

        await axios
          .get(
            this.URL +
              "api/webusers/login/" +
              this.login.email +
              "/" +
              this.login.password
          )
          .then((res) => {
            res;
            this.user = res.data.data.user;
            localStorage.setItem("localStorageName", this.user.name);
            localStorage.setItem(
              "localStoragecode",
              window.btoa(this.user.code)
            );
            localStorage.setItem(
              "localStoragerole",
              window.btoa(this.user.role)
            );
            localStorage.setItem("phone", this.user.phone1);
            localStorage.setItem("version", "0.2.5");
            localStorage.setItem("qdtoken", res.data.data.token);

            this.loading = false;

            this.$router.push({ path: "/" });
          })
          .catch((error) => {
            this.loading = false;
            //  if(error.response.status === 400){
            if (error.response.data.message != null) {
              this.message = error.response.data.message;
              if (this.message == "لم يتم تأكيد الايميل لهذا الحساب") {
                this.confirm_acc = true;
              }
            } else {
              this.message = "يجب الملء";
            }
          });
      } else {
        this.loading = true;

        await axios
          .get(
            this.URL +
              "api/Suppliers/login/" +
              this.login.email +
              "/" +
              this.login.password
          )
          .then((res) => {
            res;
            this.user = res.data.data.supplier;
            localStorage.setItem("localStorageName", this.user.name);
            localStorage.setItem(
              "localStoragecode",
              window.btoa(this.user.code)
            );
            localStorage.setItem(
              "localStoragerole",
              window.btoa(this.user.role)
            );
            localStorage.setItem("version", "0.2.5");
            localStorage.setItem("phone", this.user.phone1);
            localStorage.setItem("qdtoken", res.data.data.token);

            this.loading = false;

            this.$router.push({ path: "/" });
          })
          .catch((error) => {
            this.loading = false;
            //  if(error.response.status === 400){
            if (error.response.data.message != null) {
              this.message = error.response.data.message;
              if (this.message == "لم يتم تأكيد الايميل لهذا الحساب") {
                this.confirm_acc = true;
              }
            } else {
              this.message = "يجب الملء";
            }
          });
      }
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
      this.message = "";
    },
  },
};
</script>

<style scoped>
.backgd {
  background-color: var(--y) ;
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 20px 30px 20px;
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
  position: relative;
}

.user {
  width: 100%;
  height: 55px;
  margin-bottom: 30px;
  outline: none;
  border: none;
  background-color: whitesmoke;
  color: #111;
  font-size: 18px;
  text-align: center;
  border-radius: 5px;
  transition: 0.5s;
  border-left: 8px solid var(--y);
  border-right: 8px solid var(--y);
  font-family: var(--cairo);
  box-shadow: none !important;
}

.user:focus {
  border-left: 8px solid var(--four);
  border-right: 8px solid var(--four);
}

.btn {
  width: 95%;
  height: 55px;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 18px;
  background-color: var(--one);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  font-family: var(--cairo);
  margin-bottom: 10px;
}

.btn:hover {
  background-color: var(--y) !important;
  color: var(--tow) !important;
}
.options-1 a {
  color: #111;
  font-size: 14px;
  font-family: var(--cairo);
  margin-top: -27px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.options-1 a:hover {
  color: var(--one) !important;
}

.how {
  display: inline-flex;
  direction: rtl;
  font-family: var(--cairo);
  margin: 0px 0px -15px 0px;
}

.how .tow {
  margin-right: 10px;
  color: var(--one);
}

.how .tow:hover {
  color: var(--one) !important;
}

@media (max-width: 320px) {
  .backgd {
    background-color: var(--tow);
  }

  .bord {
    background-color: #fff;
    border: none !important;
    border-radius: 0px;
    padding: 0px;
  }

  .how {
    font-size: 14px;
  }
}
.alert {
  direction: rtl;
  font-family: var(--cairo);
  color: white;
  background-color: var(--one);
  border: none;
}

.vuelidate_input {
  border: 2px solid #df2a2a !important;
}

.vuelidate_input:focus {
  border: 2px solid #df2a2a !important;
}
.labelOk {
  padding-right: 0px !important;
}

.labelOk {
  font-family: var(--cairo);
  text-align: start;
  margin-bottom: 10px;
  direction: rtl;
  padding-right: 13px;
}

.OK {
  margin-left: 13px;
}

.form-check-input:checked {
  background-color: var(--one) !important;
  border: var(--one) !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}
</style>
