<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />
  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">
        <div style="position: relative">
          <h3 class="add_h3">قائمة الأقاليم</h3>

          <router-link to="/provinceAdd">
            <a class="btnAdd btn btn-primary text-white"
              ><i class="fas fa-plus"></i> &nbsp;اضافة اقليم</a
            >
          </router-link>
        </div>
        <div v-if="loading === false">
          <table class="table">
            <thead class="top_table">
              <tr>
                <th class="col" scope="col">اسم الاقليم</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="send in sends" :key="send.id">
                <td>
                  {{ send.name }}
                  <div class="adit_delete">
                    <a @click="Delete(send.id)" class="btn btn-danger">
                      <i class="far fa-trash-alt"></i>
                    </a>
                    <a @click="Edit(send.id)" class="btn btn-success">
                      <i class="fas fa-edit"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div>
  <img src="img/noData.jpg" alt="" style="max-width: 80%;">
  <p class="nodata">...لا يوجد لديك طرود مضافة</p>
</div> -->
        </div>
        <comloading v-if="loading == true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import appheader from "../../components/header.vue";

import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },
  props: ["URL"],

  data: function () {
    return {
      width_vue: "0",
      left_vue: "-27rem",
      loading: false,
      sends: [],
    };
  },
  created() {
    this.loading = true;
    let token = localStorage.getItem("qdtoken");
    axios
      .get(this.URL + "api/provinces", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.sends = res.data.data;
        this.loading = false;
      }).catch(() => {
        this.$router.push("/login");

      });
  },

  mounted() {
    if (window.atob(localStorage.getItem("localStoragerole")) != 159357) {
      this.$router.push({ path: "/login" });
    }
  },

  methods: {
    Edit(id) {
      localStorage.setItem("id", window.btoa(id));
      this.$router.push("/provinceEdit");
    },

    Delete(id) {
      localStorage.setItem("id", window.btoa(id));
      this.$router.push("/provinceDelete");
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 20px 50px;
}

.backgd {
  background-color: var(--tree);
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
}
.table {
  direction: rtl;
}

tr {
  text-align: start;
  font-family: var(--cairo);
}

.top_table {
  background-color: var(--one);
  color: var(--tow);
}

th,
td {
  padding: 15px 31px 15px 0px;
}
td {
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 6rem 1rem 2rem 2rem;
  font-size: 20px;
}

.btnAdd {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: none;
  position: absolute;
  top: 53px;
  left: 1px;
  padding: 7px 10px 7px 10px;
}
.btnAdd:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

.adit_delete {
  position: absolute;
  left: 4px;
  top: 11px;
}

.btn-danger {
  font-size: 15px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--one) ;
  border: solid var(--one) ;
}
.btn-danger:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

.btn-success {
  font-size: 15px;
  margin-right: 10px;
  padding-left: 10px;
  background-color: var(--one) ;
  border: solid var(--one) ;
}
.btn-success:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

@media (max-width: 625px) {
  .col {
    width: 183px;
  }
  .container {
    padding: 6rem 1rem 9rem 1rem;
    min-height: 0vh;
  }
}

@media (max-width: 411px) {
  .col {
    width: 10px;
  }
}

@media (max-width: 339px) {
  .container {
    padding: 9rem 1rem 9rem 1rem;
  }

  td,
  th {
    padding: 15px 10px 15px 0px;
  }
}

@media (max-width: 800px) {
  .bord {
    background-color: #fff;
    border: none !important;
    border-radius: 0px;
    padding: 0px;
  }
  .backgd {
    background-color: var(--tow) !important;
  }
}

.nodata {
  text-align: center;
  font-family: var(--cairo);
  color: var(--one);
  font-size: 19px;
  padding: 0px 5px 0px 10px;
}
</style>
