<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
    :Home="Home"
    :order="order"
    :movement="movement"
    :City="City"
    :account="account"
  />
  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">
        <div style="position: relative">
          <h3 v-if="loading != true" class="add_h3">
            لمعرفة اسعار التوصيل قم بختيار الفرع الذي تريده
          </h3>
        </div>
        <comloading v-if="loading == true" />

        <table v-else class="table">
          <thead class="top_table">
            <tr>
              <th class="col" scope="col">اسم الفرع</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="MainBranche in MainBranches" :key="MainBranche.id">
              <td>
                {{ MainBranche.name }}
              <div class="adit_delete">
                <a  @click="show(MainBranche.id , MainBranche.name)" class="btn btn-danger">
                    <i class="far fa-list-alt"></i>
                </a>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="loading != true" class="note">
          ملاحظة : تختلف الأسعار اذا زاد حجم الطرد على 20 عرض\ 40 طول\ 30 ارتفاع
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import appheader from "../../components/header.vue";

import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },

  props: ["URL"],
  data: function () {
    return {
      loading: false,
      width_vue: "0",
      left_vue: "-27rem",
      Home: false,
      order: false,
      movement: false,
      City: true,
      account: false,

      MainBranches: [],
    };
  },

  async created() {
    
    let token = localStorage.getItem("qdtoken");
    this.loading = true;
    await axios
      .get(this.URL + "api/branches/GetMainBranches",{ headers: { Authorization: "Bearer " + token }})
      .then((res) => {
        this.MainBranches = res.data.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  methods: {

    show(id , name){
      
      localStorage.setItem("id", window.btoa(id));
      localStorage.setItem("branchName", name);
      this.$router.push('/DeliveryPrices')
    },
    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 20px 50px;
}

.backgd {
  background-color: var(--tree);
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
}
.table {
  direction: rtl;
}

tr {
  text-align: start;
  font-family: var(--cairo);
}

.top_table {
  background-color: var(--one);
  color: var(--tow);
}

th,
td {
  padding: 15px 31px 15px 0px;
}
td {
  position: relative;
}
.add_h3 {
  text-align: center;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 20px;
}
.note {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  font-size: 15px;
  padding: 0px 5px 0px 10px;
}

.btnAdd {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: none;
  position: absolute;
  top: 53px;
  left: 1px;
  padding: 7px 10px 7px 10px;
}
.btnAdd:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

.adit_delete {
  position: absolute;
  left: 4px;
  top: 8px;
}

.btn-danger {
  font-size: 17px;
  background-color: var(--one) ;
  border: solid var(--one) ;
}
.btn-danger:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

@media (max-width: 625px) {
  .col {
    width: 183px;
  }
  .container {
    padding: 6rem 1rem 9rem 1rem;
    min-height: 0vh;
  }
}

@media (max-width: 411px) {
  .col {
    width: 10px;
  }
}

@media (max-width: 339px) {
  .container {
    padding: 9rem 1rem 9rem 1rem;
  }

  td,
  th {
    padding: 15px 10px 15px 0px;
  }
}

@media (max-width: 800px) {
  .bord {
    background-color: #fff;
    border: none !important;
    border-radius: 0px;
    padding: 0px;
  }
  .backgd {
    background-color: var(--tow) !important;
  }
}
</style>
