<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />

  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div
        v-if="message != ''"
        class="alert alert-primary d-flex align-items-center"
        role="alert"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
          viewBox="0 0 16 16"
          role="img"
          aria-label="Warning:"
        >
          <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          />
        </svg>
        <div style="padding-right: 10px">
          {{ message }}
        </div>
      </div>
      <div class="bord">
        <h3 class="add_h3">اضافة حساب</h3>

        <div class="input_label">
          <input
            v-model="users.name"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">الإسم</label>
        </div>

        <div class="input_label">
          <input
            v-model="users.email"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">الايميل</label>
        </div>

        <div class="input_label">
          <input
            v-model="users.phone1"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رقم الهاتف 1</label>
        </div>

        <div class="input_label">
          <input
            v-model="users.phone2"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رقم الهاتف 2</label>
        </div>

        <div class="input_label">
          <input
            v-model="users.address"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">العنوان</label>
        </div>
        <div class="input_label">
          <select
            @change="onChange"
            style="
              direction: rtl;
              padding-right: 40px;
              font-size: 15px;
              font-family: var(--cairo);
            "
            class="form-select"
            aria-label="Default select example"
          >
            <option  value="159357">مدير</option>
            <option value="1">زبون</option>
            <option value="3">مدير فرع</option>
          </select>

          <label class="col-form-label labl_order">صلحية المستخدم</label>
        </div>

        <div class="input_label">
          <select
          v-model="users.branchId"
            style="
              direction: rtl;
              padding-right: 40px;
              font-size: 15px;
              font-family: var(--cairo);
            "
            class="form-select"
            aria-label="Default select example"
          >
            <!-- <option>{{branchName}}</option> -->
            <option v-for="Branches in MainBranches" :key="Branches.id" :value="Branches.id">
              {{ Branches.name }}
            </option>
          </select>

          <label class="col-form-label labl_order">المكتب</label>
        </div>

        <div class="input_label">
          <input
            v-model="users.passwordHash"
            class="form-control input_order"
            type="password"
          />
          <label class="col-form-label labl_order">كلمة المرور</label>
        </div>

        <div class="input_label">
          <input class="form-control input_order" type="password" />
          <label class="col-form-label labl_order">تأكيد كلمة المرور</label>
        </div>
        <div style="text-align: start; padding-top: 20px">
          <router-link to="/accountAdminIndex">
            <a class="btnback btn btn-primary">رجوع</a>
          </router-link>
          <a @click="post_data()" class="btnsave btn btn-primary">حفظ</a>
        </div>

        <comloading v-if="loading == true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import appheader from "../../components/header.vue";

import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },

  props: ["URL"],
  data: function () {
    return {
      width_vue: "0",
      left_vue: "-27rem",
      MainBranches:[],
      branchName:"",
      branchid : "" ,
      loading: false,

      users: {
        code: "",
        name: "",
        email: "",
        phone1: "",
        emailConfirm: true,
        phone2: "",
        address: "",
        role: 159357,
        branchId:"",
        passwordHash: "",
      },
      message: "",
    };
  },

  mounted() {
    if (window.atob(localStorage.getItem("localStoragerole")) != 159357) {
      this.$router.push({ path: "/login" });
    }
  },

  async created() {
    this.loading = true;
    let token = localStorage.getItem("qdtoken");
    this.messge = false;
  
      await axios
      .get(this.URL + "api/branches/GetMainBranches",{ headers: { Authorization: "Bearer " + token }})
      .then((res) => {

        this.MainBranches = res.data.data ;
        this.loading = false;
      })
      .catch(() => {
      });
  },


  methods: {
    post_data() {
      this.loading = true;

      let token = localStorage.getItem("qdtoken");

      axios
        .post(this.URL + "api/webusers", this.users, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => {
          res;
          this.$router.push({ name: "accountAdminIndex" });
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data.message != null)
            this.message = error.response.data.message;
             this.loading = false;

        });
    },
    onChange(event) {
      if (event.target.value == "159357") {
        this.users.role = 159357;
      } else if  (event.target.value == "1") {
        this.users.role = 1;
      }
      else {
        this.users.role = 3;
      }
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 50px 50px;
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 202px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

.input_label {
  display: flex;
  padding-top: 20px;
}

.btnsave,
.btnback {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: 2px solid var(--one) ;
  padding: 5px 10px 5px 10px;
}

.btnback {
  margin-right: 10px;
}

.btnsave:hover,
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 50px;
  }
}
.alert {
  direction: rtl;
  font-family: var(--cairo);
  color: white;
  background-color: var(--one);
  border: none;
}
</style>
