<template>

  <div id="nav" style="padding: 0;">
    <!-- <appheader v-on:close="close"   v-on:open="open" :width_vue="width_vue" :left_vue="left_vue"/> -->

    <router-view v-on:close="close" :URL="URL" :URLFrontend="URLFrontend" />

    <appfooter />
  </div>
</template>


<script>
// import appheader from '../src/components/header.vue'
import appfooter from '../src/components/footer.vue'
export default {
  name: 'app',
  data: function () {
    return {
      bransh: false,
      width_vue: "0",
      left_vue: "-27rem",
      value: "",
       URL :'https://testapi.qdlibya.com/',
      // URL: 'https://demo.qdlibya.com/',

      URLFrontend: 'https://www.qdlibya.com/',
      // URLFrontend: 'https://qdl-master.herokuapp.com/',


    }
  },

  components: {
    //  appheader,
    appfooter,
  },
  methods: {
    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  }

}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}


#nav {
  padding: 30px;


}
</style>
