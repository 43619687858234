<template>
  <!-- <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  /> -->

  <div  class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">
        <h3 class="add_h3">تغيير كلمة المرور</h3>
          <div class="input_label">
            <input
            v-model="email"
            placeholder="ادخل الإيميل الخاص بك"
              name="name"
              class="text form-control input_order"
              type="text"
            />
            <!-- <label class="col-form-label labl_order">ادخل الإيميل الخاص بك</label> -->
          </div>


          <div style="text-align: start; padding-top: 20px">
                          <router-link to="/">
              <a class="btnback btn btn-primary">رجوع</a>
            </router-link>

            <button @click="send" class="btnsave btn btn-primary">تم</button>
          </div>
        <comloading v-if="loading == true" />

      </div>
    </div>
  </div>
</template>

<script>
// import appheader from "../components/header.vue";
import axios from "axios";
import comloading from "../components/Loading.vue";

export default {
  components: {
      comloading,
    // appheader,

  },
  props: ["URL","URLFrontend"],
  data: function () {
    return {
      email:"",
      loading:false,
    };
  },

methods :{


   send(){

    this.loading =true;
      axios
        .get(this.URL + "api/webusers/ChangePasswordEmail/" +this.email +"?uri="+this.URLFrontend+"changePassword")
        .then((res) => {
          res;
              this.loading =false;
          this.$router.push({ path: "/gmail" });

        }) .catch(() => {
            this.loading = false;
          });


   }

},

  
};
</script>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 50px 50px;
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  position: relative;
  min-height: 100vh;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 202px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

.input_label {
  display: flex;
  padding-top: 20px;
}

.btnsave,
.btnback {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: 2px solid var(--one) !important;
  padding: 5px 10px 5px 10px;
}

.btnback {
  margin-right: 10px;
}

.btnsave:hover,
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 50px;
  }
  .container {
    min-height: 0;
  }
}
</style>
