<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />
  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">
        <h3 class="add_h3">حذف الطرد</h3>

        <div v-if="loading === false">

        <div class="input_label">
          <input
            disabled
            v-model="orders.customerCode"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">كود المرسل</label>
        </div>

        <div class="input_label">
          <input
            disabled
            v-model="orders.storeName"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">اسم الصفحة</label>
        </div>

        <div class="input_label">
          <input
            disabled
            v-model="orders.customerPhone1"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رقم المرسل</label>
        </div>

        <div class="input_label">
          <input
            v-model="orders.recieverName"
            class="form-control input_order"
            type="text"
            disabled
          />
          <label class="col-form-label labl_order">اسم المستلم</label>
        </div>

        <div class="input_label">
          <input
            disabled
            v-model="orders.recieverPhone1"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رقم هاتف المستلم 1</label>
        </div>

        <div class="input_label">
          <input
            disabled
            v-model="orders.recieverPhone2"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رقم هاتف المستلم 2</label>
        </div>

        <div class="input_label">
          <input
            disabled
            v-model="orders.address"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">عنوان المستلم</label>
        </div>

        <div class="input_label">
          <input
            disabled
            v-model="city"
            class="form-control input_order"
            type="text"
          />

          <label class="col-form-label labl_order">المدينة</label>
        </div>

        <div class="input_label">
          <input
            disabled
            v-model="orders.packagePrice"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">قيمة الطرد</label>
        </div>

        <div class="input_label">
          <input
            disabled
            v-model="orders.packageNumber"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">عدد الطرود</label>
        </div>

        <div class="input_label">
          <input
            disabled
            v-model="orders.note"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">ملاحظة</label>
        </div>

        <div style="text-align: start; padding-top: 20px">
          <router-link to="/OrderIndex">
            <a class="btnback btn btn-primary">رجوع</a>
          </router-link>
          <a @click="delete_data()" class="btnsave btn btn-primary">حذف</a>
        </div>
           </div>
        <comloading v-if="loading == true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import appheader from "../../components/header.vue";

import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },
  props: ["URL"],
  data: function () {
    return {
      width_vue: "0",
      left_vue: "-27rem",
      loading: false,

      cities: [],

      orders: {
        id: window.atob(localStorage.getItem("id")),
        customerCode: "",
        storeName: "",
        customerPhone1: "",
        recieverName: "",
        recieverPhone1: "",
        recieverPhone2: "",
        address: "",
        cityID: "",
        packagePrice: "",
        packageNumber: "",
        note: "",
        fromBranchID: "",
        fromBranchName: "",
      },
      branchID: "",
      cityid: "",
      city: "",
    };
  },

  created() {
    this.loading = true;

    let token = localStorage.getItem("qdtoken");
    axios
      .get(this.URL + "api/WebOrders/" + this.orders.id, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.order = res.data.data;

        (this.orders.customerCode = this.order.customerCode),
          (this.orders.storeName = this.order.storeName),
          (this.orders.customerPhone1 = this.order.customerPhone1),
          (this.orders.recieverName = this.order.recieverName),
          (this.orders.recieverPhone1 = this.order.recieverPhone1),
          (this.orders.recieverPhone2 = this.order.recieverPhone2),
          (this.orders.address = this.order.address),
          (this.orders.cityID = this.order.cityID),
          (this.orders.packagePrice = this.order.packagePrice),
          (this.orders.packageNumber = this.order.packageNumber),
          (this.orders.note = this.order.note),
          (this.orders.fromBranchID = this.order.fromBranchID),
          (this.orders.fromBranchName = this.order.fromBranchName);
        this.cityid = this.order.cityID;

        axios
          .get(
            this.URL + "api/cities/GetByBranch/" + this.orders.fromBranchID,
            { headers: { Authorization: "Bearer " + token } }
          )
          .then((res) => {
            for (var i = 0; i < res.data.data.cites.length; i++) {
              if (res.data.data.cites[i].id === this.cityid) {
                this.city = res.data.data.cites[i].name;
              }
            }

            this.loading = false;
          });
      });
  },


  mounted() {
    if (window.atob(localStorage.getItem('localStoragecode')) == null) {
      this.$router.push({ path: "/login" });
    }

      if (window.atob(localStorage.getItem("localStoragerole")) == 2) {
      this.$router.push({ path: "/login" });
    }
  },

  methods: {
    delete_data() {
      this.loading = true;
    let token = localStorage.getItem("qdtoken");
      axios
        .delete(this.URL + "api/WebOrders/" + this.orders.id,{ headers: { Authorization: "Bearer " + token }})
        .then((res) => {
          res;
          this.loading = false;
          this.$router.push({ name: "OrderIndex" });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 30px 50px;
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 202px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

.input_label {
  display: flex;
  padding-top: 20px;
}

.btnsave,
.btnback {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: 2px solid var(--one) !important;
  padding: 5px 10px 5px 10px;
}

.btnback {
  margin-right: 10px;
}

.btnsave:hover,
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 30px;
  }
}
</style>
