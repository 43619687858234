<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
    :Home="Home"
    :order="order"
    :movement="movement"
    :City="City"
    :account="account"
  />
  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div
        v-if="messge === true"
        class="alert alert-primary d-flex align-items-center"
        role="alert"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
          viewBox="0 0 16 16"
          role="img"
          aria-label="Warning:"
        >
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
          />
        </svg>
        <div style="padding-right: 10px">تم التعديل</div>
      </div>
      <div class="bord">
        <h3 class="add_h3">حسابي</h3>

        <div v-if="loading === false">

         <div class="input_label">
          <input
            v-model="GetByCode.storeName"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">اسم المتجر</label>
        </div>

<div class="input_label">
          <select
            @change="onChange"
            style="
              direction: rtl;
              padding-right: 40px;
              font-size: 15px;
              font-family: var(--cairo);
            "
            class="form-select"
            aria-label="Default select example"
          >
            <option :value="branchid" selected>{{branchName}}</option>
            <option v-for="Branches in MainBranches" :key="Branches.id" :value="Branches.id">
              {{ Branches.name }}
            </option>
          </select>

          <label class="col-form-label labl_order">المكتب</label>
        </div>
        

        <div class="input_label">
          <input
            v-model="GetByCode.name"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">الإسم</label>
        </div>

        <div class="input_label">
          <input
            v-model="GetByCode.phone1"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رقم الهاتف 1</label>
        </div>

        <div class="input_label">
          <input
            v-model="GetByCode.phone2"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رقم الهاتف 2</label>
        </div>

        <div class="input_label">
          <input
            v-model="GetByCode.address"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">العنوان</label>
        </div>

        <div class="input_label">
          <input
            disabled
            v-model="myBalance"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order"> رصيدي لدى الشركة</label>
        </div>

        <div style="text-align: start; padding-top: 20px">
          <router-link to="/">
            <a class="btnback btn btn-primary">رجوع</a>
          </router-link>
          <a @click="post_data()" class="btnsave btn btn-primary">حفظ</a>
        </div>
        </div>
        <comloading v-if="loading == true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import appheader from "../../components/header.vue";

import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },

  props: ["URL"],

  data: function () {
    return {
      MainBranches:[],
      myBalance:"",
      loading: false,
      width_vue: "0",
      left_vue: "-27rem",
      Home: false,
      order: false,
      movement: false,
      City: false,
      account: true,
      branchid : "" ,
      branchName:"",
       messge: false,
      GetByCode: {
        id: "",
        storeName:"",
        branchId:"",
        name: "",
        phone1: "",
        phone2: "",
        address: "",
      },
    };
  },

  mounted() {
    if (window.atob(localStorage.getItem('localStoragecode')) == null) {
      this.$router.push({ path: "/login" });
    }

      if (window.atob(localStorage.getItem("localStoragerole")) == 2) {
      this.$router.push({ path: "/login" });
    }
  },

  async created() {
    this.loading = true;
    let token = localStorage.getItem("qdtoken");
    this.messge = false;
    await axios
      .get(
        this.URL +
          "api/webusers/GetByCode?code=" +
          window.atob(localStorage.getItem("localStoragecode")),{ headers: { Authorization: "Bearer " + token }})
      .then((res) => {
        this.GetByCode = res.data.data;

      })
      .catch(() => {
                this.$router.push("/login");

      });


      await axios
      .get(this.URL + "api/branches/GetMainBranches",{ headers: { Authorization: "Bearer " + token }})
      .then((res) => {
        
        for (var i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].id !=  this.GetByCode.branchId) {
                this.MainBranches.push(res.data.data[i]);
              } else {
                this.branchName = res.data.data[i].name;
              }
            }

      })
      .catch(() => {
      });

    await axios
      .get(
        this.URL +
          `api/Orders/GetCustomerBalance/` +
          window.atob(localStorage.getItem("localStoragecode")),{ headers: { Authorization: "Bearer " + token }})
      .then((res) => {
        this.myBalance = res.data.data;
        this.loading = false;
      })
      .catch(() => {});
      
  },
  methods: {

     onChange(event) {
      this.GetByCode.branchId = event.target.value;
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },

    post_data() {

      let token = localStorage.getItem("qdtoken");
      this.loading = true;
      axios
        .put(this.URL + "api/webusers", this.GetByCode ,{ headers: { Authorization: "Bearer " + token }})
        .then((res) => {
          res;
          this.loading = false;
          this.$router.push({ path: "/" });
          localStorage.setItem("localStorageName", this.GetByCode.name);
          localStorage.setItem("phone", res.data.data.phone1);

          this.messge = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 30px 50px;
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 202px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

.input_label {
  display: flex;
  padding-top: 20px;
}

.btnsave,
.btnback {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: 2px solid var(--one) ;
  padding: 5px 10px 5px 10px;
}

.btnback {
  margin-right: 10px;
}

.btnsave:hover,
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 30px;
  }
}

.alert {
  direction: rtl;
  font-family: var(--cairo);
  color: var(--tow);
  background-color: var(--one);
  border: none;
}
</style>
