<template>

       <div class="backgd">
<div class="container" style=" max-width: 900px;">
    <div class="bord">
       <h3 class="add_h3">تم تأكيد الحساب</h3>
       <div style="text-align: center;">
       <router-link  class="btn login" to="/login" >تسجيل الدخول</router-link>
       </div>
 </div>
   
  </div>
   </div>
</template>


<script>
import axios from "axios"

export default {
props:['URL'],
  data:function(){
    return {

      useId:this.$route.params.useId,
      code:'',
      name:'',
      width_vue: "0",
      left_vue: "-27rem",


    }
    },

async created(){

 await  axios.get(this.URL+'api/webusers/EmailConfirm/'+this.useId).then((res)=>{
res
})
  
}
}
</script>



<style scoped>
.backgd {
  background-color:var(--tree);
}

.bord {
    background-color: #fff;
    border: solid 4px var(--one)!important;
    border-radius: 10px;
    padding: 5px 50px 50px 50px;
}
.container {
        padding: 9rem 2rem 9rem 2rem;
        min-height: 100vh;
        position: relative;
    }
.add_h3 {
text-align: end;
 font-family: var(--cairo);
   color: var(--one);
   padding: 5rem 1rem 2rem 2rem;
    font-size: 22px;
}

.login {
  margin-top: 1rem;
  display: inline-block;
  font-size: 1.7rem;
  color: var(--tow);
  background-color: var(--one);
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.8rem 3rem;
  font-family: var(--cairo);
}

.btn:hover {
  background-color: var(--tow);
  color: var(--one);
  border: 2px solid var(--one);
  font-weight: bold;
}




</style>