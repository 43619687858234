<template>
<appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />
     <div @click="close()" class="backgd">
<div class="container" style=" max-width: 900px;">
    <div class="bord">
       <h3 class="add_h3">إتفاقية الخدمة :</h3>


        <ul class="ULTerms">
                    <li>
                        <p>1-  نعلمكم بعدم مسؤوليتنا عن ضياع او تلف البضاعة في حالة وقوع سطو مسلح لاقدر الله داخل مكاتبنا او في الطريق حيث اننا حريصون بعدم المسير ليلا بالشاحنات الا في الظروف الاستثنائية.</p>
                    </li>

                    <li>
                        <p>
                            2- نخلي المسؤولية عن اي بضاعة لم يبلغ صاحبها عن قابليتها للكسر او اذا كانت غير مغلفة تغليفا جيدا.
                        </p>
                    </li>

                    <li>
                        <p>
                            3- يمنع منعا باتا شحن الاسلحة والذخائر والالعاب النارية والسجائر وماشبهها والمواد القابلة للإشتعال من وقود وغاز الطهي.
                        </p>
                    </li>
                    <li>
                        <p>
                            4- يرجى من الزبون المرسل للبضاعة تحري الدقة في اسم المستلم ووجة البضاعة ورقم الهاتف .
                        </p>
                    </li>
                    <li>
                        <p>
                            5- على مرسل البضاعة اخبار المستلم بسعر الشحن ورقم الطرد مباشرة وفي حالة المراجعة يجب احضار ايصال الشحن.
                        </p>
                    </li>
                    <li>
                        <p>
                            6- نظرا للظروف الراهنة للبلاد نلتمس منكم العذر بسبب اغلاق الطريق او اعطال الشاحنات المفاجئ اثناء الرحلة.
                        </p>
                    </li>

                    <li>
                        <p>
                            7- نعلمكم بعدم مسؤوليتنا عن فقدان او تلف اي بضاعة بعد مضي 3 اشهر من تاريخ وصولها للمكتب.
                        </p>
                    </li>
                    <li>
                        <p>
                            8- يحق لشركة كويك دليفري مع ضمان سلامة الشحنة الحق في المعاينة وتفتيش جميع الشحنات.
                        </p>
                    </li>
                    <li>
                        <p>
                            9- يتعهد المرسل بعدم استخدام خدماتنا بما يتعارض مع القانون والاخلاق او للأعمال الحتيالية.
                        </p>
                    </li>

                </ul>

   <div style="text-align: start;
   " >
    <router-link to="/createAccount">
     <a class="btnback btn btn-primary">رجوع</a>
    </router-link>
     </div>
   </div>
</div>
</div>
    
</template>

<script>
import appheader from "../components/header.vue";

export default {
  components: {
    appheader,
  },
data:function(){
    return {
      width_vue: "0",
      left_vue: "-27rem",}},

methods: {
  open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
}
};
</script>


<style scoped>


.backgd {
  background-color:var(--tree);
}

.bord {
    background-color: #fff;
    border: solid 4px var(--one)!important;
    border-radius: 10px;
    padding: 5px 50px 30px 50px;
}
.container {
        padding: 9rem 2rem 9rem 2rem;
        min-height: 100vh;
        position: relative;
    }
.add_h3 {
text-align: start;
 font-family: var(--cairo);
   color: var(--one);
   padding: 5rem 1rem 2rem 2rem;
    font-size: 22px;
        direction: rtl;

}

.ULTerms {
    margin-top: 5px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e5e3e3;
}

.ULTerms p {
 font-family: var(--cairo);
    font-size: 14px;
    direction: rtl;
    text-align: initial;
    padding-bottom: 10px;
}


 .btnback  {

    font-size: 15px;
    font-family: var(--cairo);
    background-color: var(--one);
    color: var(--tow);
  border: 2px solid var(--one) ;
    padding: 5px 10px 5px 10px;


}
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

@media (max-width:600px) {
    
    .bord {
padding: 5px 27px 30px 10px;
}

}
</style>