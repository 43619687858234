<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />
  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">
        <h3 class="add_h3">بيانات الطرد</h3>
        <div v-if="loading === false">
          <div class="input_label">
            <input
              disabled
              v-model="orders.barCode"
              class="text form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">كود الطرد</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.status"
              class="text form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">حالة الطرد</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.storeName"
              class="text form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">اسم الصفحة</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.customerPhone1"
              class="text form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">رقم المرسل</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.recieverName"
              class="form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">اسم المستلم</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.recieverPhone1"
              class="form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">رقم هاتف المستلم 1</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.recieverPhone2"
              class="form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">رقم هاتف المستلم 2</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.address"
              class="form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">عنوان المستلم</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="city"
              class="form-control input_order"
              type="text"
            />

            <label class="col-form-label labl_order">المدينة</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.packagePrice"
              class="form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">قيمة الطرد</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.packageNumber"
              class="form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">عدد الطرود</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.note"
              class="form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">ملاحظة</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.deliveryPrice"
              class="form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">سعر التوصيل</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.totalPrice"
              class="form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">اجمالي السعر</label>
          </div>

          <div class="input_label">
            <input
              disabled
              v-model="orders.date"
              class="form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order"
              >تاريخ التسليم للمكتب</label
            >
          </div>

          <div style="text-align: start; padding-top: 20px">
            <router-link to="/movement">
              <a class="btnback btn btn-primary">رجوع</a>
            </router-link>
          </div>
        </div>
        <comloading v-if="loading == true" />
      </div>
    </div>
  </div>
</template>

<script>
import appheader from "../../components/header.vue";
import axios from "axios";

import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },

  props: ["URL"],
  data: function () {
    return {
      width_vue: "0",
      left_vue: "-27rem",
      loading: false,
      orders: {
        id: window.atob(localStorage.getItem("id")),
        storeName: "",
        customerPhone1: "",
        recieverName: "",
        recieverPhone1: "",
        recieverPhone2: "",
        address: "",
        packagePrice: "",
        packageNumber: "",
        note: "",
        deliveryPrice: "",
        totalPrice: "",
        date: "",
        barCode: "",
        status: "",
      },
      branchID: "",
      city: "",
    };
  },

  async created() {
    this.loading =true;
    let token = localStorage.getItem("qdtoken");
    await axios
      .get(this.URL + "api/Orders/" + this.orders.id, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.order = res.data.data;
        this.orders.barCode = this.order.barCode;
        this.orders.status = this.order.status.name;
        (this.orders.storeName = this.order.storeName),
          (this.orders.customerPhone1 = this.order.customerPhone1),
          (this.orders.recieverName = this.order.recieverName),
          (this.orders.recieverPhone1 = this.order.recieverPhone1),
          (this.orders.recieverPhone2 = this.order.recieverPhone2),
          (this.orders.address = this.order.address),
          (this.city = this.order.city.name);
        this.orders.deliveryPrice = this.order.deliveryPrice;
        this.orders.date = this.convert(this.order.stateDate);
        this.orders.totalPrice = this.order.totalPrice;
        (this.orders.packagePrice = this.order.packagePrice),
          (this.orders.packageNumber = this.order.packageNumber),
          (this.orders.note = this.order.note),
          (this.orders.fromBranchID = this.order.fromBranchID),
          (this.orders.fromBranchName = this.order.fromBranchName);
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  mounted() {
    if (window.atob(localStorage.getItem("localStoragecode")) == null) {
      this.$router.push({ path: "/login" });
    }

    if (window.atob(localStorage.getItem("localStoragerole")) == 2) {
      this.$router.push({ path: "/login" });
    }
  },

  methods: {

    convert(event) {
      let d = new Date(event);
      if (isNaN(d.getFullYear()) === true) {
        return "-";
      }
      return (
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()

        // +
        // " , "
        // +
        // d.getHours() +
        // ":" +
        // d.getMinutes()
      );
    },
    
    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 50px 50px;
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 202px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

.input_label {
  display: flex;
  padding-top: 20px;
}

.btnsave,
.btnback {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: 2px solid var(--one) !important;
  padding: 5px 10px 5px 10px;
}

.btnback {
  margin-right: 10px;
}

.btnsave:hover,
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 50px;
  }
}
</style>
