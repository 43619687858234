<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />

  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">
        <h3 class="add_h3">اضافة حركة</h3>
        <form @submit.prevent="post_data()">
          <div class="input_label">
            <input
              v-model="statuse.name"
              class="text form-control input_order"
              type="text"
            />
            <label class="col-form-label labl_order">اسم الحركة</label>
          </div>

          <label class="col-12 labelOk form-check-label">
            <input
              v-model="statuse.ShowToCustomer"
              class="OK form-check-input"
              id="Ok"
              type="checkbox"
            />
            اظهار الحالة لزبون
          </label>

          <label
            class="col-12 labelOk form-check-label"
            style="margin-top: 10px"
          >
            <input
              v-model="statuse.MainStatus"
              class="OK form-check-input"
              id="Ok"
              type="checkbox"
            />
            الحالة الرئيسية
          </label>

          <label
            class="col-12 labelOk form-check-label"
            style="margin-top: 10px"
          >
            <input
              v-model="statuse.AccountAmountStatus"
              class="OK form-check-input"
              id="Ok"
              type="checkbox"
            />
            اضافة القيمة لزبون
          </label>

          <label
            class="col-12 labelOk form-check-label"
            style="margin-top: 10px"
          >
            <input
              v-model="statuse.ShowToSupplier"
              class="OK form-check-input"
              id="Ok"
              type="checkbox"
            />
              اظهار الحالة للمندوب
          </label>

          <label
            class="col-12 labelOk form-check-label"
            style="margin-top: 10px"
          >
            <input
              v-model="statuse.ShowToOtherBranches"
              class="OK form-check-input"
              id="Ok"
              type="checkbox"
            />
              اظهار الحالة لباقي الفروع
          </label>


          <div style="text-align: start; padding-top: 20px">
            <router-link to="/movementIndix">
              <a class="btnback btn btn-primary">رجوع</a>
            </router-link>
            <button type="submit" class="btnsave btn btn-primary">حفظ</button>
          </div>
        </form>
        <comloading v-if="loading == true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import appheader from "../../components/header.vue";

import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },

  props: ["URL"],
  data: function () {
    return {
      width_vue: "0",
      left_vue: "-27rem",
      loading: false,
      ShowToCustomer: false,
      MainStatus: false,
      AccountAmountStatus: false,

      statuse: {
        name: "",
        ShowToCustomer: false,
        MainStatus: false,
        AccountAmountStatus: false,
        ShowToSupplier:false,
        ShowToOtherBranches:false,
      },
    };
  },

  mounted() {
    if (window.atob(localStorage.getItem("localStoragerole")) != 159357) {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    post_data() {
      this.loading = true;

      let token = localStorage.getItem("qdtoken");
      axios
        .post(this.URL + "api/statuses", this.statuse, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => {
          res;
          this.$router.push({ name: "movementIndix" });
          this.loading = false;
        });
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 50px 50px;
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 202px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

.input_label {
  display: flex;
  padding-top: 20px;
}

.btnsave,
.btnback {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: 2px solid var(--one) ;
  padding: 5px 10px 5px 10px;
}

.btnback {
  margin-right: 10px;
}

.btnsave:hover,
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 50px;
  }
  .container {
    min-height: 0;
  }
}

.labelOk {
  padding-right: 0px !important;
}

.labelOk {
  font-family: var(--cairo);
  text-align: start;
  margin-bottom: 10px;
  direction: rtl;
  margin-top: 33px;
  padding-right: 13px;
}

.OK {
  margin-left: 13px;
}

.form-check-input:checked {
  background-color: var(--one) !important;
  border: var(--one) !important;
}
</style>
