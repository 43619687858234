<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />

  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">
        <h3 class="add_h3">اضافة طرد</h3>

        <div class="input_label">
          <input
            disabled
            v-model="orders.customerCode"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">كود المرسل</label>
        </div>

        <div class="input_label">
          <input
            v-model="orders.storeName"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">اسم الصفحة</label>
        </div>

        <div class="input_label">
          <input
            v-model="v$.orders.customerPhone1.$model"
            :class="{ vuelidate_input: v$.orders.customerPhone1.$error }"
            :placeholder="massge"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رقم المرسل</label>
        </div>

        <div class="input_label">
          <input
            v-model="orders.recieverName"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">اسم المستلم</label>
        </div>

        <div class="input_label">
          <input
            v-model="v$.orders.recieverPhone1.$model"
            :class="{ vuelidate_input: v$.orders.recieverPhone1.$error }"
            :placeholder="massge"
            class="form-control input_order"
            type="number"
          />
          <label class="col-form-label labl_order">رقم هاتف المستلم 1</label>
        </div>

        <div class="input_label">
          <input
            v-model="orders.recieverPhone2"
            class="form-control input_order"
            type="number"
          />
          <label class="col-form-label labl_order">رقم هاتف المستلم 2</label>
        </div>

        <div class="input_label">
          <input
            v-model="orders.address"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">عنوان المستلم</label>
        </div>

        <div class="input_label">
          <select
            :placeholder="massge"
            :class="{ vuelidate_input: v$.orders.cityID.$error }"
            @change="onChange"
            style="
              direction: rtl;
              padding-right: 40px;
              font-size: 15px;
              font-family: var(--cairo);
            "
            class="form-select"
            aria-label="Default select example"
          >
            <option selected></option>
            <option v-for="city in cities" :key="city.id" :value="city.id">
              {{ city.name }}
            </option>
          </select>

          <label class="col-form-label labl_order">المدينة</label>
        </div>

        <div class="input_label">
          <input
            v-model="v$.orders.packagePrice.$model"
            :class="{ vuelidate_input: v$.orders.packagePrice.$error }"
            :placeholder="massge"
            class="form-control input_order"
            type="number"
          />
          <label class="col-form-label labl_order">قيمة الطرد</label>
        </div>

        <div class="input_label">
          <input
            v-model="orders.packageNumber"
            class="form-control input_order"
            type="number"
          />
          <label class="col-form-label labl_order">عدد الطرود</label>
        </div>

        <div class="input_label">
          <input
            v-model="orders.note"
            class="form-control input_order"
            type="text"
          />
          <label class="labl_order">ملاحظة</label>
        </div>

        <div style="text-align: start; padding-top: 20px">
          <router-link to="/OrderIndex">
            <a class="btnback btn btn-primary">رجوع</a>
          </router-link>
          <a @click="post_data()" class="btnsave btn btn-primary">حفظ الطرد</a>
        </div>
        <comloading v-if="loading == true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import appheader from "../../components/header.vue";
import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },

  props: ["URL"],
  data: function () {
    return {
      v$: useVuelidate(),
      loading: false,
      width_vue: "0",
      left_vue: "-27rem",

      massge: "",
      orders: {
        customerCode: "",
        storeName: "",
        customerPhone1: "",
        recieverName: "",
        recieverPhone1: "",
        recieverPhone2: "",
        address: "",
        cityID: "",
        packagePrice: "",
        packageNumber: null,
        note: "",
        fromBranchID:window.atob(localStorage.getItem("id")),
        fromBranchName:localStorage.getItem("branchName"),
      },

      mainbranches: {
        branchId:  window.atob(localStorage.getItem("id")),
      },
      cities: [],
    };
  },
  validations() {
    return {
      orders: {
        recieverPhone1: {
          required,
        },

        cityID: {
          required,
        },

        packagePrice: {
          required,
        },
        customerPhone1: {
          required,
        },
      },
    };
  },
  //

  async created() {

     this.orders.customerPhone1= localStorage.getItem("phone");

    let token = localStorage.getItem("qdtoken");
    await axios
      .get(this.URL + "api/cities/GetByBranch/" + this.mainbranches.branchId,{ headers: { Authorization: "Bearer " + token }})
      .then((res) => {
        this.cities = res.data.data.cites;
      });
  },


  mounted() {
    this.orders.customerCode = window.atob(localStorage.getItem("localStoragecode"));
    if (window.atob(localStorage.getItem('localStoragecode')) == null) {
      this.$router.push({ path: "/login" });
    }

      if (window.atob(localStorage.getItem("localStoragerole")) == 2) {
      this.$router.push({ path: "/login" });
    }
  },

  methods: {


    onChange(event) {
      this.orders.cityID = event.target.value;
    },

    post_data() {
    let token = localStorage.getItem("qdtoken");
      this.loading = true;
      this.v$.$validate();
      if (!this.v$.$error) {
        axios
          .post(this.URL + "api/WebOrders", this.orders,{ headers: { Authorization: "Bearer " + token }})
          .then((res) => {
            res;
            this.loading = false;
            this.$router.push({ name: "OrderIndex" });
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.massge = "يجب الملء";
        this.loading = false;
      }
      
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 30px 50px;
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 202px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

.input_label {
  display: flex;
  padding-top: 20px;
}

.btnsave,
.btnback {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: 2px solid var(--one) ;
  padding: 5px 10px 5px 10px;
}

.btnback {
  margin-right: 10px;
}

.btnsave:hover,
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 30px;
  }
}

.vuelidate_input {
  border: 2px solid #df2a2a !important;
}

.vuelidate_input:focus {
  border: 2px solid #df2a2a !important;
}
::placeholder {
  color: #df2a2a;
}
</style>
