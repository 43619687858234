<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />

  <div @click="close()" class="backgd">
    <div class="container container_quick" style="max-width: 900px">
      <div
        v-if="message != ''"
        class="alert alert-primary d-flex align-items-center"
        role="alert"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
          viewBox="0 0 16 16"
          role="img"
          aria-label="Warning:"
        >
          <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          />
        </svg>
        <div style="padding-right: 10px">
          {{ message }}
        </div>
      </div>

      <div class="bord">
        <h3 class="add_h3">إنشاء حساب</h3>

         <div class="input_label">
          <input
            v-model="v$.Register.StoreName.$model"
            :class="{ vuelidate_input: v$.Register.StoreName.$error }"
            :placeholder="massge"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">اسم الصفحة</label>
        </div>

         <div class="input_label">
          <select
            :placeholder="massge"
            :class="{ vuelidate_input: v$.Register.BranchID.$error }"
            @change="onChange"
            style="
              direction: rtl;
              padding-right: 40px;
              font-size: 15px;
              font-family: var(--cairo);
            "
            class="form-select"
            aria-label="Default select example"
          >
            <option selected></option>
            <option v-for="Branches in MainBranches" :key="Branches.id" :value="Branches.id">
              {{ Branches.name }}
            </option>
          </select>

          <label class="col-form-label labl_order">المكتب</label>
        </div>


        <div class="input_label">
          <input
            v-model="v$.Register.name.$model"
            :class="{ vuelidate_input: v$.Register.name.$error }"
            :placeholder="massge"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">الإسم</label>
        </div>

        <div class="input_label">
          <input
            v-model="v$.Register.email.$model"
            :class="{ vuelidate_input: v$.Register.email.$error }"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">الايميل</label>
        </div>
        <div class="container" style="max-width: 340px">
          <p
            class="p_valid"
            v-if="v$.Register.email.$error && v$.Register.email != ''"
          >
            يجب ادخال الإيميل
          </p>
        </div>
        <div class="input_label">
          <input
            v-model="v$.Register.phone1.$model"
            :class="{ vuelidate_input: v$.Register.phone1.$error }"
            :placeholder="massge"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رقم الهاتف 1</label>
        </div>

        <div class="input_label">
          <input
            v-model="Register.phone2"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رقم الهاتف 2</label>
        </div>

        <div class="input_label">
          <input
            v-model="v$.Register.address.$model"
            :class="{ vuelidate_input: v$.Register.address.$error }"
            :placeholder="massge"
            class="form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">العنوان</label>
        </div>

        <div class="input_label">
          <input
            v-model.trim="v$.Register.passwordHash.$model"
            :class="{ vuelidate_input: v$.Register.passwordHash.$error }"
            class="form-control input_order"
            type="password"
          />
          <label class="col-form-label labl_order">كلمة المرور</label>
        </div>

        <div class="container" style="max-width: 340px">
          <p
            class="p_valid"
            v-if="
              v$.Register.passwordHash.$error && v$.Register.passwordHash != ''
            "
          >
            يجب ادخال كلمة المرور
          </p>
        </div>

        <div class="input_label">
          <input
            :placeholder="massgepass"
            v-model.trim="v$.repeatPassword.$model"
            :class="{
              invalid:
                Register.passwordHash != repeatPassword && repeatPassword != '',
            }"
            class="form-control input_order"
            type="password"
          />
          <label class="col-form-label labl_order">تأكيد كلمة المرور</label>
        </div>
        <div class="container" style="max-width: 340px">
          <p
            class="p_valid"
            v-if="
              Register.passwordHash != repeatPassword &&
              repeatPassword != messge
            "
          >
            كلمة المرور غير متطابقة
          </p>
        </div>
        <!-- <p >{{isDisabled}}</p>
     <p v-if="!v$.confirmPassword.sameAsPassword">sameAsPassword</p> -->

        <label class="col-12 labelOk form-check-label">
          <input
            @click="checked_Tr_me()"
            class="OK form-check-input"
            id="Ok"
            type="checkbox"
          />
          أوافق على
          <router-link to="/Terms">
            <a class="Terms_a">شروط الخدمة</a>
          </router-link>
        </label>

        <div style="text-align: start; padding-top: 20px">
          <button
            v-if="checked_Tr == false"
            class="btnsave btn btn-primary"
            disabled="false"
          >
            تسجيل
          </button>

          <a v-else @click="post_data()" class="btnsave btn btn-primary"
            >تسجيل</a
          >
        </div>
        <comloading v-if="loading == true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, sameAs, email } from "@vuelidate/validators";

import appheader from "../components/header.vue";
import comloading from "../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },

  props: ["URL", "URLemailconfirm","URLFrontend"],
  data: function () {
    return {
      MainBranches : [] ,
      massgepass: "",
      messge: "",
      display: true,
      code: "",
      name: "",
      width_vue: "0",
      left_vue: "-27rem",
      loading: false,
      statuses: [],
      v$: useVuelidate(),
      message: "",
      sameAsPassword: "",
      repeatPassword: "",
      Password: "",
      checked_Tr: false,
      Register: {
        StoreName:"",
        BranchID : "" ,
        name: "",
        email: "",
        phone1: "",
        phone2: "",
        address: "",
        passwordHash: "",
      },
    };
  },

  validations() {
    return {
      Register: {
        name: { required },
        email: { required, email },
        phone1: { required },
        StoreName:{required},
        BranchID:{required},
        address: { required },
        passwordHash: { required },
      },

      repeatPassword: {
        sameAsPassword: sameAs("Register.passwordHash"),
      },
    };
  },

  async created() {
    
    let token = localStorage.getItem("qdtoken");
    await axios
      .get(this.URL + "api/branches/GetMainBranches",{ headers: { Authorization: "Bearer " + token }})
      .then((res) => {
        this.MainBranches = res.data.data;
      })
      .catch(() => {
      });
  },

  methods: {

    onChange(event) {
      this.Register.BranchID = event.target.value;
    },

    post_data() {
      this.loading = true;
      this.v$.$validate();
      if (this.repeatPassword == this.Register.passwordHash) {

        axios
          .post(
            this.URL +
              "api/webusers/Register?uri=" +
              this.URLFrontend +
              "EmailConfirm" ,
            this.Register
          )
          .then((res) => {
            res;
            this.loading = false;
            this.$router.push({ path: "/togmail" });
          })
          .catch((error) => {
            this.loading = false;
            this.massge = "يجب الملء";

            //  if(error.response.status === 400){
            if (error.response.data.message != null)
              this.message = error.response.data.message;
          });
      } else {
        this.loading = false;
        if (this.repeatPassword == "")
          this.massgepass = "يجب تاكيد كلمة المرور";
      }
    },

    checked_Tr_me() {
      this.checked_Tr = !this.checked_Tr;
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
      this.message = "";
    },
  },
};
</script>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 30px 50px;
}
.container_quick {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 202px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

.input_label {
  display: flex;
  padding-top: 20px;
}

.btnsave,
.btnback {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: 2px solid var(--one) !important;
  padding: 5px 10px 5px 10px;
}

.btnback {
  margin-right: 10px;
}

.btnsave:hover,
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 30px;
  }

  .labelOk {
    padding-right: 0px !important;
  }
}
.labelOk {
  font-family: var(--cairo);
  text-align: start;
  margin-bottom: 10px;
  direction: rtl;
  margin-top: 33px;
  padding-right: 13px;
}

.OK {
  margin-left: 13px;
}

.form-check-input:checked {
  background-color: var(--one) !important;
  border: var(--one) !important;
}

.Terms_a {
  color: var(--one) !important;
}

.Terms_a:hover {
  color: var(--one) !important;
  font-weight: bold;
}

.invalid {
  border: 2px solid #df2a2a !important;
}

.invalid:focus {
  border: 2px solid #df2a2a !important;
}

.valid {
  border: 2px solid #389d2c !important;
}
.valid:focus {
  border: 2px solid #389d2c !important;
}
.p_valid {
  color: #df2a2a;
  text-align: end;
  font-family: var(--cairo);
  font-size: 12px;
  padding-top: 6px;
}
.alert {
  direction: rtl;
  font-family: var(--cairo);
  color: white;
  background-color: var(--one);
  border: none;
}

.vuelidate_input {
  border: 2px solid #df2a2a !important;
}

.vuelidate_input:focus {
  border: 2px solid #df2a2a !important;
}
::placeholder {
  color: #df2a2a;
}
</style>
