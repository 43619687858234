<template>
  <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />

  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">
        <h3 class="add_h3">اضافة فرع</h3>

        <div class="input_label">
          <input
            v-model="branches.name"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">اسم الفرع</label>
        </div>

        <div class="input_label">
          <input
            v-model="branches.code"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">رمز الفرع</label>
        </div>



        <div class="input_label">
          <select
            @change="onChange"
            style="
              direction: rtl;
              padding-right: 40px;
              font-size: 15px;
              font-family: var(--cairo);
            "
            class="form-select"
            aria-label="Default select example"
          >
            <option value="1" selected></option>
            <option
              v-for="(MainBranche, index) in MainBranches"
              :key="index"
              :value="MainBranche.id"
            >
              {{ MainBranche.name }}
            </option>
          </select>

          <label class="col-form-label labl_order"> الفرع الرئيسي</label>
        </div>

 <div class="input_label">
          <input
            v-model="branches.Phone"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order"> 1 رقم الهاتف</label>
        </div>

         <div class="input_label">
          <input
            v-model="branches.Phone2"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order"> 2 رقم الهاتف</label>
        </div>

          <div class="input_label">
          <input
            v-model="branches.location"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">الموقع</label>
        </div>


        <div style="text-align: start; padding-top: 20px">
          <router-link to="/brunchAdminIndex">
            <a class="btnback btn btn-primary">رجوع</a>
          </router-link>
          <a @click="post_data()" class="btnsave btn btn-primary">حفظ</a>
        </div>
        <comloading v-if="loading == true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import appheader from "../../components/header.vue";

import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },

  props: ["URL"],
  data: function () {
    return {
      width_vue: "0",
      left_vue: "-27rem",
      loading: false,

      MainBranches: {
        id: "",
        name: "",
      },

      branches: {
        name: "",
        code: "",
        parantId: null,
        Phone:"",
        Phone2:"",
        location:"",

      },
    };
  },

  created() {
    axios.get(this.URL + "api/branches/GetMainBranches").then((res) => {
      this.MainBranches = res.data.data;
    });
  },

  mounted() {
    if (window.atob(localStorage.getItem("localStoragerole")) != 159357) {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    onChange(event) {
      if (event.target.value == 1) {
        this.branches.parantId = null;
      } else this.branches.parantId = event.target.value;
    },

    post_data() {
      this.loading = true;

      let token = localStorage.getItem("qdtoken");
      axios
        .post(this.URL + "api/branches", this.branches, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => {
          res;
          this.$router.push({ name: "brunchAdminIndex" });
          this.loading = false;
        });
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 50px 50px;
}
.container {
  padding: 9rem 2rem 9rem 2rem;
  min-height: 100vh;
  position: relative;
}
.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 202px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

.input_label {
  display: flex;
  padding-top: 20px;
}

.btnsave,
.btnback {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: 2px solid var(--one) ;
  padding: 5px 10px 5px 10px;
}

.btnback {
  margin-right: 10px;
}

.btnsave:hover,
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }
  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 50px;
  }
  .container {
    min-height: 0;
  }
}
</style>
