import { createRouter, createWebHistory } from "vue-router";
import Home from '../views/Home'
import OrderIndex from '../views/order/OrderIndex'
import OrderAdd from '../views/order/OrderAdd'
import OrderEdit from '../views/order/OrderEdit'
import OrderDelete from '../views/order/OrderDelete'

import Brunsh from '../views/order/Brunsh'
import BrunshCity from '../views/city/Brunsh'
import DeliveryPrices from '../views/city/DeliveryPrices'
import movement from '../views/movement/index'
import detalis from '../views/movement/detalis'
import account from '../views/account/account'
import provinceIndex from '../views/provinceAdmin/provinceIndex'
import provinceAdd from '../views/provinceAdmin/provinceAdd'
import provinceEdit from '../views/provinceAdmin/provinceEdit'
import provinceDelete from '../views/provinceAdmin/provinceDelete'
import movementIndix from '../views/movementAdmin/movementIndix'
import movementAdd from '../views/movementAdmin/movementAdd'
import movementEdit from '../views/movementAdmin/movementEdit'
import movementDelete from '../views/movementAdmin/movementDelete'
import brunchAdminIndex from '../views/brunchAdmin/brunchAdminIndex'
import brunchAdminAdd from '../views/brunchAdmin/brunchAdminAdd'
import brunchAdminEdit from '../views/brunchAdmin/brunchAdminEdit'
import brunchAdminDelete from '../views/brunchAdmin/brunchAdminDelete'
import accountAdminIndex from '../views/accountAdmin/accountAdminIndex'
import accountAdminAdd from '../views/accountAdmin/accountAdminAdd'
import accountAdminEdit from '../views/accountAdmin/accountAdminEdit'
import accountAdminDelete from '../views/accountAdmin/accountAdminDelete'
import createAccount from '../views/createAccount'
import login from '../views/login'
import Terms from '../views/Terms'
import emailconfirm  from '../views/emailconfirm'
import togmail  from '../views/togmail'
import LS  from '../views/localStorage'
import Delegates_index from '../views/Delegates/index'
import Delegates_detalis from '../views/Delegates/detalis'
import Email from '../views/Email'
import change_Password from '../views/change Password'
import gmail from '../views/gmail'
let version = "0.2.4";
const routes = [


  {
    path: '/',
    //  redirect:'/home',
     repleace:'/Home',
     name: 'Home',
    component: Home,

    // name => Guards

  },
  

   {
     path:'/OrderIndex',
     name:'OrderIndex',
     component:OrderIndex,

     beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}
 
   },
   {
     path:'/OrderAdd',
     name:'OrderAdd',
     component:OrderAdd,

     beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

   },

   {
    path:'/OrderEdit',
    name:'OrderEdit',
    component:OrderEdit,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

  },

  {
    path:'/OrderDelete',
    name:'OrderDelete',
    component:OrderDelete,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

  },

   {
     path:'/Brunsh',
     name:'Brunsh',
     component:Brunsh,

     beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

   },

   {
    path:'/BrunshCity',
    name:'BrunshCity',
    component:BrunshCity,


  },
  {
    path:'/DeliveryPrices',
    name:'DeliveryPrices',
    component:DeliveryPrices,


  },
  {
    path:'/movement',
    name:'movement',
    component:movement,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

  },
  {
    path:'/detalis',
    name:'detalis',
    component:detalis,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

  },

  {
    path:'/account',
    name:'account',
    component:account,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

  },

  {
    path:'/provinceIndex',
    name:'provinceIndex',
    component:provinceIndex,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

  },

  {
    path:'/provinceAdd',
    name:'provinceAdd',
    component:provinceAdd
  },

  {
    path:'/provinceEdit',
    name:'provinceEdit',
    component:provinceEdit
  },


  {
    path:'/provinceDelete',
    name:'provinceDelete',
    component:provinceDelete
  },


  {
    path:'/movementIndix',
    name:'movementIndix',
    component:movementIndix,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}


    
  },


  {
    path:'/movementAdd',
    name:'movementAdd',
    component:movementAdd
  },

  {
    path:'/movementEdit',
    name:'movementEdit',
    component:movementEdit
  },

  {
    path:'/movementDelete',
    name:'movementDelete',
    component:movementDelete
  },


  {
    path:'/brunchAdminIndex',
    name:'brunchAdminIndex',
    component:brunchAdminIndex,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

  },


  {
    path:'/brunchAdminAdd',
    name:'brunchAdminAdd',
    component:brunchAdminAdd
  },



  {
    path:'/brunchAdminEdit',
    name:'brunchAdminEdit',
    component:brunchAdminEdit
  },


  {
    path:'/brunchAdminDelete',
    name:'brunchAdminDelete',
    component:brunchAdminDelete
  },


  {
    path:'/accountAdminIndex',
    name:'accountAdminIndex',
    component:accountAdminIndex,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

  },


  {
    path:'/accountAdminAdd',
    name:'accountAdminAdd',
    component:accountAdminAdd
  },


  {
    path:'/accountAdminEdit',
    name:'accountAdminEdit',
    component:accountAdminEdit
  },


  {
    path:'/accountAdminDelete',
    name:'accountAdminDelete',
    component:accountAdminDelete
  },
  {
    path:'/createAccount',
    name:'createAccount',
    component:createAccount,


  },
  {
    path:'/login',
    name:'login',
    component:login
  },

  {
    path:'/Terms',
    name:'Terms',
    component:Terms
  },
  
  {
    path:'/EmailConfirm/:useId',
    name:'EmailConfirm',
    component:emailconfirm
  },

  {
    path:'/togmail',
    name:'togmail',
    component:togmail
  },

  {
    path:'/LS',
    name:'اصدار جديد',
    component:LS
  },

  {
    path:'/Delegates',
    name:'Delegates',
    component:Delegates_index,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

  },

  {
    path:'/Delegates-Detalis',
    name:'Delegates_detalis',
    component:Delegates_detalis,

    beforeEnter :(to, from, next) => {
      if(localStorage.getItem("version") == version || localStorage.getItem("version") == null){
        next("/login")

      }
      else{
        next();
      }
}

  },

  {
    path:'/Email',
    name:'Email',
    component:Email
  },

  {
    path:'/changePassword/:useId',
    name:'changePassword',
    component:change_Password
  },

  {
    path:'/gmail',
    name:'gmail',
    component:gmail
  },


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
