<template>
  <appheader :width_vue="width_vue" :left_vue="left_vue" v-on:close="close" v-on:open="open" />

  <div @click="close()" class="backgd">
    <div class="container" style="max-width: 900px">
      <div class="bord">
        <h3 class="add_h3">تعديل الفرع</h3>
        <div v-if="loading === false">
          <div class="input_label">
            <input v-model="parant.name" class="text form-control input_order" type="text" />
            <label class="col-form-label labl_order">اسم الفرع</label>
          </div>

          <div class="input_label">
            <input v-model="parant.code" class="text form-control input_order" type="text" />
            <label class="col-form-label labl_order">رمز الفرع</label>
          </div>

          <div class="input_label">
            <select @change="onChange" style="
                direction: rtl;
                padding-right: 40px;
                font-size: 15px;
                font-family: var(--cairo);
              " class="form-select" aria-label="Default select example">
              <option value="1"></option>
              <option v-if="brancheName != ''" selected :value="parant.parantId">
                {{ brancheName }}
              </option>
              <option v-for="(send, index) in MainBranches" :key="index" :value="send.id">
                {{ send.name }}
              </option>
            </select>

            <label class="col-form-label labl_order"> الفرع الرئيسي</label>
          </div>

          <div class="input_label">
            <input v-model="parant.Phone" class="text form-control input_order" type="text" />
            <label class="col-form-label labl_order"> 1 رقم الهاتف</label>
          </div>


          <div class="input_label">
            <input v-model="parant.Phone2" class="text form-control input_order" type="text" />
            <label class="col-form-label labl_order">2 رقم الهاتف</label>
          </div>

           <div class="input_label">
          <input
            v-model="parant.location"
            class="text form-control input_order"
            type="text"
          />
          <label class="col-form-label labl_order">الموقع</label>
        </div>

          <div style="text-align: start; padding-top: 20px">
            <router-link to="/brunchAdminIndex">
              <a class="btnback btn btn-primary">رجوع</a>
            </router-link>
            <a @click="put_data()" class="btnsave btn btn-primary">تعديل</a>
          </div>
        </div>
        <comloading v-if="loading == true" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import appheader from "../../components/header.vue";

import comloading from "../../components/Loading.vue";
export default {
  components: {
    appheader,
    comloading,
  },
  props: ["URL"],
  data: function () {
    return {
      width_vue: "0",
      left_vue: "-27rem",
      loading: false,
      branches: [],
      brancheName: "",
      parant: {
        id: window.atob(localStorage.getItem("id")),
        name: "",
        code: "",
        parantId: "",
        parantname: "",
        Phone: "",
        Phone2: "",
        location:"",
      },

      MainBranches: [],
    };
  },

  created() {
    this.loading = true;
    let token = localStorage.getItem("qdtoken");
    axios
      .get(this.URL + "api/branches/" + this.parant.id, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        axios.get(this.URL + "api/branches/GetMainBranches",
          {
            headers: { Authorization: "Bearer " + token },
          }
        ).then((res) => {
          for (var i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].id != this.parant.parantId) {
              this.MainBranches.push(res.data.data[i]);
            } else {
              this.brancheName = res.data.data[i].name;
            }
            this.loading = false;
          }
        });

        this.branches = res.data.data;
        this.parant.name = this.branches.name;
        this.parant.code = this.branches.code;
        this.parant.parantId = this.branches.parantID;
        this.parant.Phone = this.branches.phone;
        this.parant.Phone2 = this.branches.phone2;
        this.parant.location = this.branches.location;
      });
  },

  mounted() {
    if (window.atob(localStorage.getItem("localStoragerole")) != 159357) {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    onChange(event) {
      if (event.target.value == 1) {
        this.parant.parantId = null;
      } else this.parant.parantId = event.target.value;
    },

    put_data() {
      this.loading = true;

      let token = localStorage.getItem("qdtoken");
      axios
        .put(this.URL + "api/branches", this.parant, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => {
          res;
          this.$router.push({ name: "brunchAdminIndex" });
          this.loading = false;
        });
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
      this.message = "";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
.backgd {
  background-color: var(--tree);
}

.bord {
  background-color: #fff;
  border: solid 4px var(--one) !important;
  border-radius: 10px;
  padding: 5px 50px 50px 50px;
}

.container {
  padding: 9rem 2rem 9rem 2rem;
  position: relative;
  min-height: 100vh;
}

.add_h3 {
  text-align: end;
  font-family: var(--cairo);
  color: var(--one);
  padding: 5rem 1rem 2rem 2rem;
  font-size: 22px;
}

.labl_order {
  width: 100px;
  font-family: var(--cairo);
  color: var(--one);
  width: 202px;
  margin-right: 13px;
  margin-left: 54px;
  text-align: end;
}

.input_order {
  direction: rtl;
  font-family: var(--cairo);
  font-size: 15px;
  padding-right: 18px;
}

.input_label {
  display: flex;
  padding-top: 20px;
}

.btnsave,
.btnback {
  font-size: 15px;
  font-family: var(--cairo);
  background-color: var(--one);
  color: var(--tow);
  border: 2px solid var(--one) ;
  padding: 5px 10px 5px 10px;
}

.btnback {
  margin-right: 10px;
}

.btnsave:hover,
.btnback:hover {
  background-color: var(--tow) !important;
  color: var(--one) !important;
  border: 2px solid var(--one) !important;
  font-weight: bold !important;
}

input:focus,
select:focus,
option:hover {
  box-shadow: none !important;
  border: 2px solid var(--one) !important;
}

@media (max-width: 700px) {
  .input_label {
    direction: rtl;
    flex-direction: column-reverse;
  }

  .labl_order {
    text-align: start !important;
    margin-right: 5px;
  }

  .add_h3 {
    text-align: center;
    padding: 3rem 1rem 2rem 2rem;
  }

  .bord {
    padding: 5px 22px 50px;
  }

  .container {
    min-height: 0;
  }
}
</style>
