<template>
  <div>
    <appheader :width_vue="width_vue" :left_vue="left_vue" v-on:close="close" v-on:open="open" :Home="Home" :order="order"
      :movement="movement" :City="City" :account="account" :name="name" />

    <!------------  home  ----------------->

    <section class="home" id="home">
      <div style=" background-color: var(--one);
  margin-bottom: 50px" class="home-slider">
        <div class="wrapper">
          <div @click="close()" class="slide">
            <div class="content">
              <h3 style="color: var(--y)">Quick Delivary</h3>
              <p style="color: var(--tow)">
                مش مهم وين مكانك المهم الشحنة حتوصلك كويك ديلفري خيارك الافضل
              </p>
              <div class="login_create_account">
                <router-link v-if="name === null" class="btn login" to="/login">
                  تسجيل الدخول</router-link>

                <a v-else class="btn login" @click="signOut"> تسجيل الخروج</a>

                <router-link class="btn create_account" to="/createAccount">
                  انشاء حساب
                </router-link>
              </div>
            </div>

            <div class="image">
              <img src="img/qd-car.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!------------  about ------------------>
    <section class="about_special">
      <div class="container" style="max-width: 123rem; padding-right: 30px; padding-left: 30px">
        <h1 class="about_h1">عن كويك ديلفري</h1>
        <p class="about_p">
          هي احدى الشركات المتميزة و الرائدة في مجال التوصيل والشحن السريع في
          دولة ليبيا والتي تطورت بشكل كبير بفضل الله أولا ثم بجهود الكوادر
          العاملة وثقة عملائها ثانيا لتصبح شركة محترفه في مجالها حيث تقوم شركة
          كويك ديلفري بتغطية معظم مدن ومناطق ليبيا الحبيبة
        </p>
      </div>

      <!------------  special ------------------>
      <div class="container" style="max-width: 123rem; padding-bottom: 30px">
        <h1 class="about_h1" style="padding-top: 10px">مايميّزنا</h1>

        <div class="all_card">
          <div class="card">
            <img src="img/فريق.jpg" class="card-img-top" alt="" />
            <div class="card-body">
              <p class="card-text">لدينا فريق توصيل متكامل</p>
            </div>
          </div>

          <div class="card">
            <img src="img/سرعة توصيل.jpg" class="card-img-top" alt="" />
            <div class="card-body">
              <p class="card-text">سرعة توصيل ثابتة</p>
            </div>
          </div>

          <div class="card">
            <img src="img/الإلتزام والدقة في التوصيل.jpg" class="card-img-top" alt="" />
            <div class="card-body">
              <p class="card-text">الإلتزام والدقة في التوصيل</p>
            </div>
          </div>

          <div class="card">
            <img src="img/الكفاءة والتفوق.jpg" class="card-img-top" alt="" />
            <div class="card-body">
              <p class="card-text">الكفاءة والتفوق</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!------------  add ------------------>

    <section class="home container" id="home">
      <div class="home-slider">
        <div class="wrapper">
          <div class="slide paddingadd">
            <div class="content">
              <h3 class="titl_h3">خدمة إضافة طرد</h3>
              <p>
                يوفر لك موقع كويك ديلفري الوقت بإضافه طلبيتك من بيتك ومن ثم
                التوجه للشركة لتسليم الطلبيه من غير ماتقعد اتراجي وهكي طلعت
                راابح وكسبت وقتك
              </p>
            </div>

            <div class="image">
              <img src="img/add.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!------------  move ------------------>

    <section class="home container" id="home">
      <div class="home-slider">
        <div class="wrapper">
          <div class="slide paddingmove">
            <div class="content">
              <h3 class="titl_h3">خدمة تتبع الطرود</h3>
              <p class="font_size">
                خدمة تتبع الطرود من أفضل الخدمات اللي تقدمها الشركة واللي تريح
                الزبون هلبا بحيث يعرف ويطمن على شحنته وين واصلت هل تم الإستلام
                او عند المندوب او راجع.... وهكي تقدر تقود المشروع الخاص بك
                بسهوله وراحة بال
              </p>
            </div>

            <div class="image">
              <img src="img/move.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!------------  call ------------------>

    <section>
      <div class="call container" style="max-width: 123rem">
        <h1 class="about_h1" style="padding-top: 10px; padding-bottom: 7px">
          اتصل بنا
        </h1>
        <p class="about_p" style="
              font-size: 1.7rem;
              padding: 1rem 2rem 2.4rem 2rem;
              text-align: center;
            ">
          للمزيد من المعلومات ولمعرفة التفاصيل يمكنكم الاتصال بنا
        </p>

        <div class="all_card">
          <div v-for="branche in branches" :key="branche.id" class="card">
            <div class="card-body">
              <p class="card-text">{{ branche.name }}</p>
              <a class="a_face" :href="`tel:${branche.phone}`">
                <p style="direction: ltr">{{ branche.phone }}</p>
              </a>
              <a class="a_face" :href="`tel:${branche.phone2}`">
                <p style="direction: ltr">{{ branche.phone2 }}</p>
              </a>
            </div>
          </div>

          <!-- <div class="card">
            <div class="card-body">
              <p class="card-text">{{branche.phone2}}</p>
              <a class="a_face" href="tel:092 353 4525">
                <p style="direction: ltr">092 353 4525</p></a
              >
              <a class="a_face" href="tel:091 413 0500"
                ><p style="direction: ltr">091 413 0500</p></a
              >
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-text">مكتب بنغازي</p>
              <a class="a_face" href="tel:094 417 0203">
                <p style="direction: ltr">094 417 0203</p></a
              >
              <a class="a_face" href="tel:091 300 8866">
                <p style="direction: ltr">091 300 8866</p></a
              >
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-text">مكتب البيضاء</p>
              <a class="a_face" href="tel:092 052 7151">
                <p style="direction: ltr">092 052 7151</p></a
              >
              <a class="a_face" href="tel:091 990 0709">
                <p style="direction: ltr">091 990 0709</p></a
              >
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-text">مكتب مصراتة</p>
              <a class="a_face" href="tel:092 264 6160">
                <p style="direction: ltr">092 264 6160</p></a
              >
              <a class="a_face" href="tel:092 269 6160">
                <p style="direction: ltr">092 269 6160</p></a
              >
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-text">مكتب صبراتة</p>
              <a class="a_face" href="tel:092 846 7000">
                <p style="direction: ltr">092 846 7000</p></a
              >
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-text">مكتب سبها</p>
              <a class="a_face" href="tel:094 510 8887">
                <p style="direction: ltr">094 510 8887</p></a
              >
              <a class="a_face" href="tel:091 510 8887">
                <p style="direction: ltr">091 510 8887</p></a
              >
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-text">مكتب الزاوية</p>
              <a class="a_face" href="tel:092 750 5536">
                <p style="direction: ltr">092 750 5536</p></a
              >
              <a class="a_face" href="tel:091 400 6606">
                <p style="direction: ltr">091 400 6606</p></a
              >
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-text">مكتب الخمس</p>
              <a class="a_face" href="tel:092 313 3859">
                <p style="direction: ltr">092 313 3859</p></a
              >
              <a class="a_face" href="tel:092 261 6756">
                <p style="direction: ltr">092 261 6756</p></a
              >
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-text">مكتب ترهونة</p>
              <a class="a_face" href="tel:092 766 1011">
                <p style="direction: ltr">092 766 1011</p></a
              >
              <a class="a_face" href="tel:091 065 0618">
                <p style="direction: ltr">091 065 0618</p></a
              >
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-text">مكتب زليتن</p>
              <a class="a_face" href="tel:092 910 5910">
                <p style="direction: ltr">092 910 5910</p></a
              >
              <a class="a_face" href="tel:091 510 5910">
                <p style="direction: ltr">091 317 8284</p></a
              >
            </div>
          </div>

        

          <div class="card">
            <div class="card-body">
              <p class="card-text">مكتب درنة</p>
              <a class="a_face" href="tel:094 472 2580">
                <p style="direction: ltr">094 472 2580</p></a
              >
            </div> -->
          <!-- </div> -->

          <div class="card">
            <div class="card-body">
              <p class="card-text">البريـد الإلكتروني</p>
              <p style="direction: ltr">
                qdlibya@gmail.com
                <i style="color: var(--one)" class="far fa-envelope"></i>
              </p>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-text">صفحتنا على الفيس</p>
              <p style="direction: ltr; font-family: var(--cairo);}">
                <a class="a_face"
                  href="https://www.facebook.com/%D9%83%D9%88%D9%8A%D9%83-%D8%AF%D9%8A%D9%84%D9%81%D9%8A%D8%B1%D9%8AQuick-delivery-160957531211914">كويك
                  ديلفري
                  <i style="color: var(--one)" class="fab fa-facebook"></i></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import appheader from "../components/header.vue";
import axios from "axios";


export default {
  name: "Home",
  components: {
    appheader,
  },
  props: ["URL"],


  data: function () {
    return {
      Home: true,
      order: false,
      movement: false,
      City: false,
      account: false,
      test: [],
      branches: [],
      code: "",
      name: localStorage.getItem("localStorageName"),
      version: localStorage.getItem("version"),
      width_vue: "0",
      left_vue: "-27rem",
    };
  },


  created() {


    this.loading = true;
    let token = localStorage.getItem("qdtoken");
    axios
      .get(this.URL + "api/branches/GetMainBranches", {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        this.branches = res.data.data;

      });





  },


  methods: {
    signOut() {
      localStorage.setItem("localStorageName", "");
      localStorage.clear("localStoragecode");
      localStorage.clear("phone", "");
      localStorage.setItem("localStoragerole", null);
      localStorage.setItem("version", "0.2.2");
      localStorage.clear("idstate");
      this.$router.push({ path: "/login" });
    },

    open() {
      this.width_vue = "27rem";
      this.left_vue = "0";
    },

    close() {
      this.width_vue = "0";
      this.left_vue = "-27rem";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");

/* ---------------home------------ */

.home .home-slider .slide {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 7rem 2rem 0 0;
 
}

.home .home-slider .slide .content {
  flex: 1 1 45rem;
}

.home .home-slider .slide .image {
  flex: 1 1 45rem;
}

.home .home-slider .slide .image img {
  width: 100%;
}

.home .home-slider .slide .content h3 {
  font-family: "Kaushan Script", cursive;
  color: var(--one);
  font-size: 5rem;
}

.home .home-slider .slide .content p {
  color: #000;
  font-size: 2.2rem;
  padding: 2.5rem 0;
  line-height: 1.5;
  font-family: var(--cairo);
}

.create_account {
  margin-top: 1rem;
  display: inline-block;
  font-size: 1.7rem;
  color: var(--one);
  background-color: var(--y);
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.8rem 3rem;
  font-family: var(--cairo);
  font-weight: bold;
}

.login {
  margin-top: 1rem;
  display: inline-block;
  font-size: 1.7rem;
  background-color: var(--tow);
  color: var(--one);
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.8rem 3rem;
  font-family: var(--cairo);
  margin-right: 30px;
}

.btn:hover {
  background-color: var(--tow);
  color: var(--one);
  border: 2px solid var(--one);
  font-weight: bold;
}

.home .home-slider .paddingadd {
  padding: 3rem 2rem 5rem !important;
}

.home .home-slider .paddingmove {
  padding: 0rem 2rem 5rem !important;
}

@media (max-width: 540px) {
  .home .home-slider .slide .content h3 {
    font-size: 3rem;
  }

  .home .home-slider .slide .content p {
    font-size: 1.7rem;
    padding: 2.1rem 0;
  }

  .home .home-slider .slide {
    padding: 10rem 2rem;
  }

  .font_size {
    font-size: 1.6rem !important;
  }
}

@media (max-width: 390px) {
  .login {
    margin-right: 0px;
    margin-top: 0px;
  }

  .login_create_account {
    display: flex;
    flex-direction: column;
  }

  .home .home-slider .slide {
    gap: 0;
  }
}

@media (max-width: 1199px) {
  .home .home-slider .slide {
    padding: 11rem 2rem;
  }
}

/* ---------------about------------ */

.about_h1 {
  color: var(--one);
  font-family: var(--cairo);
  font-weight: bold;
  font-size: 2.3rem;
  text-align: center;
  padding-bottom: 30px;
}

.about_p {
  color: #000;
  font-family: var(--cairo);
  text-align: center;
  padding-bottom: 30px;
  font-size: 1.7rem;
  direction: rtl;
}

@media (max-width: 1000px) {
  .home .home-slider .slide {
    padding: 9rem 2rem 5rem;
  }
}

@media (max-width: 600px) {
  .about_h1 {
    font-size: 25px;
  }

  .about_p {
    text-align: justify;
  }
}

/* ---------------special------------ */

.card {
  box-shadow: 0.5px 0.5px 10px var(--one) !important;
  border: none !important;
}

.all_card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1.5rem;
  padding-right: 20px;
  padding-left: 20px;
  direction: rtl;
}

.card-text {
  font-size: 1.7rem;
  color: var(--one);
  background-color: var(--y);
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.8rem 3rem;
  font-family: var(--cairo);
  font-weight: bold;
}

@media (max-width: 343px) {
  .all_card {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 1170px) {
  .card-text {
    font-size: 1.4rem;
    padding: 0.8rem 0rem;
  }
}

/* ---------------add & move------------ */

.titl_h3 {
  font-family: var(--cairo) !important;
  font-size: 2.3rem !important;
  font-weight: bold !important;
}

/* ---------------call------------ */
.call {
  padding-bottom: 122px;
}

.a_face {
  color: #000;
}

.a_face:hover {
  color: var(--one);
  font-weight: bold;
}

@media (max-width: 1000px) {
  .call {
    padding-bottom: 30px;
  }
}
</style>
