<template>
 <appheader
    :width_vue="width_vue"
    :left_vue="left_vue"
    v-on:close="close"
    v-on:open="open"
  />
       <div class="backgd">
<div class="container" style=" max-width: 900px;">
    <div class="bord">
       <h3 class="add_h3">لقد قمنا بتحديث الموقع الرجاء اعادة تسجيل الدخول</h3>

 </div>
  </div>
   </div>
</template>


<script>
import axios from "axios"
import appheader from "../components/header.vue";
  

export default {

    components: {
    appheader,
  },
props:['URL'],
  data:function(){
    return {
          email:this.$route.params.email,
                code:'',
      name:'',
      width_vue: "0",
      left_vue: "-27rem",


    }
    },

async created(){

 await  axios.get(this.URL+'api/webusers/EmailConfirm/'+this.email).then((res)=>{
res
})
  
}
}
</script>



<style scoped>
.backgd {
  background-color:var(--tree);
}

.bord {
    background-color: #fff;
    border: solid 4px var(--one)!important;
    border-radius: 10px;
    padding: 5px 50px 50px 50px;
}
.container {
        padding: 9rem 2rem 9rem 2rem;
        min-height: 100vh;
        position: relative;
    }
.add_h3 {
text-align: end;
 font-family: var(--cairo);
   color: var(--one);
   padding: 5rem 1rem 2rem 2rem;
    font-size: 22px;
}


</style>